@import "~STYLES/colors.scss";
@import "~STYLES/fonts.scss";
@import "~STYLES/pixelConvertConstants.scss";

/// This class is used to style CustomSearch Component
/// @group custom-components
.custom-select {
    height: 2.5rem !important;

    .custom-option-title {
        @extend .SegoeUIWhite16px;
    }

    .custom-group-title {
        @extend .SegoeUIWhite16pxBold;
        text-transform: capitalize;
    }

    .custom-option-subtitle {
        @extend .SegoeUI14px;
    }

    //search bar
    .custom-contact-search__control {
        height: 2.5rem;
        border-radius: $fourpx;
        border: $onepx solid white !important;
        cursor: pointer;

        &.custom-contact-search__control--is-focused {
            box-shadow: none;
        }

        .search-icon {
            margin-left: 0.6rem;
        }

        .clear-icon {
            height: 0.875em;
            width: 0.875em;
            cursor: pointer !important;
        }

        .custom-contact-search__value-container {

            //search input text
            .custom-contact-search__input-container {
                @extend .SegoeUIWhite16px;
                color: $textColor1 !important;
                cursor: pointer !important;
                border: $onepx soild white !important;

                //text typed as an input
                .custom-contact-search__input {
                    border: none !important;
                    cursor: pointer !important;
                }

                input[type=text]:focus {
                    border: none !important;
                }
            }

            //search bar placeholder
            .custom-contact-search__placeholder {
                cursor: pointer !important;
                color: $textColor1 !important;
                opacity: 0.8;
            }

            .custom-contact-search__indicators {
                cursor: pointer !important;
            }
        }
    }

    // dropdown options list menu
    .custom-contact-search__menu {
        border: $onepx solid white;
        margin-top: 0% !important;
        border-radius: $fourpx;
        z-index: 999 !important;

        //dropdownlist container
        .custom-contact-search__menu-list {
            max-height: 21.875rem !important;

            .custom-contact-search__group {
                padding: 0rem;
                border-bottom: 0.03rem solid $parDivBorder;
            }

            // options
            .custom-contact-search__option {
                color: $textColor1 !important;
                height: 3.875rem;

                // hover on an option
                &:hover {
                    height: 3.875rem;
                    cursor: pointer !important;
                }
            }
            
            .custom-contact-search__menu-notice--no-options {
                color: $textColor1 !important;
                cursor: default;
            }
        }
    }
}