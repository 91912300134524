@import '~STYLES/colors.scss';
@import '~STYLES/globalStyles.scss';
@import '../src/resources/styles/fonts.scss';
@import '~STYLES/pixelConvertConstants.scss';

@font-face {
    font-family: 'SegoeUI';
    src: url('./resources/fonts/segoeui.ttf');
}

@font-face {
    font-family: 'SegoeUI Bold';
    src: url('./resources/fonts/segoeuib.ttf');
}

@font-face {
    font-family: 'SegoeUI SemiBold';
    src: url('./resources/fonts/seguisb.ttf');
}

// html{
//     height: 100%;
// }

#root {
    height: 100% !important;
}

.viewport-height{
    height: 100vh !important;
}

body {
    @extend .SegoeUI;
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: 100% !important;
    color: $textColor4 !important;
    //font-family: 'Segoe UI';
    background-color: $backgroundColor1 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
    width: $ninepx;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: $tenpx;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $iconBorder;
    border-radius: $tenpx;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $borderColor2;
}

::-webkit-scrollbar-thumb:touch {
    border-radius: 10px;
    background-color: $iconBorder;
    -webkit-box-shadow: 0 0 5px grey;
}