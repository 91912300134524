@import "~STYLES/colors.scss";
@import "~STYLES/fonts.scss";
@import "~STYLES/pixelConvertConstants.scss";

/// This class is used to style CustomModal Component
/// @group custom-components
.custom-dropdown {
  color: $textColor1;
  position: relative;
  width: 100%;

  .dropdown {
    .dropdown-toggle {
      text-align: left;
      @extend .SegoeUI14px;
      color: $textColor1;
      font-size: $fourteenpx;
      background: transparent;
      border: $onepx solid $borderColor3;
      height: $thirtysixpx;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .btn-secondary.dropdown-toggle {
      color: $white;
      background-color: transparent;
      border-color: $borderColor3;
    }

    .btn-secondary.dropdown-toggle:focus {
      box-shadow: none;
    }

    .dropdown-menu > .dropdown-item:hover {
      background-color: $dropdown-hover;
    }

    .dropdown-toggle::after {
      display: none;
    }

    .dropdown-item {
      @extend .SegoeUI14px;
      border: none;
      height: auto;
      color: $textColor1;
      white-space: normal;
      word-break: break-word;
    }

    .dropdown-menu.show {
      width: 100%;
      min-width: 100%;
      z-index: 9999;
      background-color: transparent;
      border-color: $borderColor3;
      color: inherit;

      .btn-secondary.dropdown-toggle {
        color: $textColor1;
      }
    }

    .selected-value {}

    .no-value-width {
      width: $twohundredpx !important;
    }

    .no-value {
      @extend .SegoeUI14px;
      padding: $onepx;
      display: flex;
      justify-content: center;
    }

    .dropdown-toggle:focus,
    .dropdown-toggle:active {
      color: $textColor1;
      border: $onepx solid $borderColor3;
      box-shadow: none;
    }

    .dropdown-toggle:disabled {
      background-color: transparent !important;
    }

    .btn.disabled,
    .btn:disabled {
      opacity: 1;
    }

    .select-text {
      @extend .SegoeUI14px;
      opacity: 0.75; //0.3;
      display: flex;
      align-items: center;
    }
  }
}