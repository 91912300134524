@import '~STYLES/colors.scss';
@import '~STYLES/fonts.scss';
@import '~STYLES/pixelConvertConstants.scss';

/// This class is used to style CustomModal Component
.custom-modal {
    // background:$backgroundColor3 0% 0% no-repeat padding-box;
    // box-shadow: 0px 3px 15px #000000D6;
    max-width: 88%;
    opacity: 1;
    .modal-body {
        // max-width: 500px;
        place-content: 'center';
        line-height: 21px;

        .calling-name {
            @extend .SegoeUIWhite16pxBold;
            display: flex;
            align-items: center;
            justify-content: center;
            letter-spacing: 0.05rem;
            word-break: break-all;
        }
        .calling-text {
            @extend .SegoeUIWhiteSemi16px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            letter-spacing: 0.05rem;
            word-break: break-all;
        }
        .modal-body-contents {
            @extend .SegoeUIWhite14px;
            text-align: left; // left;
            letter-spacing: 0rem;
            color: $textColor1;
            opacity: 1;
        }
        .centered-button {
            place-content: center;
            margin: 0rem;
        }
        .action-buttons {
            width: $hundredpx;
            height: $thirtyfivepx;
            display: flex;
            color: $textColor1;
            border-radius: 0em;
        }
        .cancel-button {
            margin-right: $tenpx;
        }
        .permission-button {
            @extend .SegoeUI14Semibold;
        }
        .margin-auto {
            margin: auto;
        }
        .cen-text {
            text-align: -webkit-center;
        }
    }
}
