@import '~STYLES/colors.scss';
@import '~STYLES/fonts.scss';

@import '~STYLES/pixelConvertConstants.scss';

/// This class is used to style Header Component
/// @group header
.header-container {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;

    .full-height {
        height: 100%;
        display: flex;
        align-items: center;
    }

    .header {
        height: 4.563rem !important;

        .icons-set {
            display: flex;
            justify-content: flex-end;
            padding-right: 1.2rem;
        }

        .logo {
            max-width: 50%;
        }

        @media (max-width: $phone) {
            .icons-set {
                padding-left: 1.5rem;
            }
        }

        @media (min-width: $phone) and (max-width: $tablet) {
            .icons-set {
                padding-right: 1rem;
            }
        }

        .icons-width {
            @extend .full-height;
            width: 4.8%;
            cursor: pointer;
            color: $textColor1;
            justify-content: center;
            margin-left: 0.1rem;

            &.disabled {
                opacity: 0.5;
                pointer-events: none;
            }

        }

        .icons-width:focus-visible {
            outline: $onepx dashed $borderColor3 !important;
        }

        .disable-icon:hover{
            background-color: transparent !important;
            cursor: default !important;
        }
        @media (max-width: $phone) {
            .icons-width {
                width: 12.5%;
            }
        }

        @media (min-width: $phone) and (max-width: $tablet) {
            .icons-width {
                width: 9.5%;
            }
        }

        @media (min-width: $tablet) and (max-width: $laptop) {
            .icons-width {
                width: 8.5%;
            }
        }

        @media (min-width: $laptop) and (max-width: $desktop) {
            .icons-width {
                width: 6.5%;
            }
        }

        @media (min-width: 1201px) {
            .icons-width {
                width: 4.25rem;
            }

            .group-icons-width {
                width: 6rem !important;
            }
        }

        .pad-right {
            margin-right: 0.6rem;
            margin-left: -0.3rem !important; // Modified for bug #650
        }

        .group-icons-width {
            @extend .full-height;
            cursor: pointer;
            color: $textColor1;
            justify-content: center;
            width: 6.7%;
            margin-left: 0.1rem;

            &.disabled,
            .disabled-icon {
                opacity: 0.5;
                pointer-events: none;
            }
        }

        .group-icons-width:focus-visible {
            outline: $onepx dashed $borderColor3 !important;
        }

        // Added classes for bug #650 -  to have hover effect separate for icons and chevron/arrow
        .actual-icon {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 0% !important;
            padding-right: 0% !important;
        }
        &.disabled,
        .disabled-icon {
            opacity: 0.5;
            pointer-events: none;
            cursor: default;
            .icons-width{
                cursor: default !important;
            }
        }
        .chevron-arrow {
            margin-left: 0px;
            padding: 0px !important;
            justify-content: center;
            align-items: center;
        }

        .actual-icon:focus-visible,
        .chevron-arrow:focus-visible {
            outline: $onepx dashed $borderColor3 !important;
        }

        .modified-row {
            padding: 0 !important;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100% !important;
        }

        .modified-group-icons {
            padding: 0 !important;
        }

        @media (max-width: $phone) {
            .group-icons-width {
                width: 21.5%;
            }
        }

        @media (min-width: $phone) and (max-width: $tablet) {
            .group-icons-width {
                width: 15%;
            }
        }

        @media (min-width: $tablet) and (max-width: $laptop) {
            .group-icons-width {
                width: 10.5%;
            }
        }

        @media (min-width: $laptop) and (max-width: $desktop) {
            .group-icons-width {
                width: 7.8%;
            }
        }

        .left-border {
            border-left: $onepx solid $iconBorder;
            height: 75%;
            margin: auto 0;
        }

        .dropdown-container {
            @extend .full-height, .align-center;
            width: 100%;
            justify-content: center;

            .btn {
                border-radius: 0;
                padding: 0;
            }

            .btn-secondary {
                height: 100%;
                width: 100%;
                border: 0;
                box-shadow: none;
                background-color: transparent;
            }

            & :hover {
                box-shadow: none;
            }

            .dropdown-item:focus-visible {
                background-color: transparent !important;
                outline: none;
            }

            .btn:focus-visible {
                outline: $onepx dashed $borderColor3 !important;
            }

            .dropdown-menu {
                background-color: $backgroundColor2;
                padding: 1.525rem 1.413rem;
                width: 14.375rem;
                box-shadow: -3px 5px 5px $shadowColor1;
                top: 3px !important;
                color: inherit;

                .dropdown-item {
                    color: $textColor1;
                    padding: 0.3rem;
                }

                .dropdown-item.disabled,
                .dropdown-item:disabled {
                    opacity: 0.5 !important;
                    cursor: none;
                    pointer-events: none !important;
                }

                .dropdown-label {
                    @extend .SegoeUI22px;
                }

                .svg-icon {
                    vertical-align: bottom;
                }
            }
        }
    }
}

// More actions menu for mobile
.more-action-items {
    @extend .SegoeUIWhite16px;
    color: $textColor1 !important;
    cursor: pointer;
}

.more-actions-header {
    @extend .SegoeUI20pxBold;
    color: $textColor1 !important;
    text-align: center;
    letter-spacing: 0em;
    opacity: 1;
}