@import "~STYLES/colors.scss";
@import "~STYLES/fonts.scss";

@import "~STYLES/pixelConvertConstants.scss";

/// This class is used to style TextFieldComponent
/// @group custom-components
.text-field-component {
  color: $textColor1;
  position: relative;
  display: flex;
  align-items: center;

  .form-control {
    @extend .SegoeUI14px;
    color: $textColor1 !important;
    width: 100%;
    min-height: 1em;
    text-align: left;
    background: $backgroundColor2 !important;
    background-image: none !important;
    outline: 0 !important;
    box-shadow: none;
    border: $onepx solid $borderColor1;
    border-radius: 0rem;
    height: 2.5rem;

    &:focus {
      box-shadow: none;
    }

    &[data-left-icon="true"] {
      padding-left: 2.25em;
    }

    &[data-right-icon="true"] {
      padding-right: 2.8em;
    }

    &[data-right-icon-2="true"] {
      padding-right: 5em;
    }
  }

  .form-control:disabled,
  .form-control[readonly] {
    background-color: $backgroundColor2 !important;
    border: $onepx solid $borderColor1;
    // border-radius: 5px;
  }

  .form-control::placeholder {
    margin-top: 0.3em;
    opacity: 0.3;
    @extend .SegoeUI20px;
    color: $textColor1;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0rem $hundredpx $backgroundColor2 inset;
    box-shadow: 0 0 0rem $thousandpx $backgroundColor2 inset;
    -webkit-text-fill-color: $textColor4;
    caret-color: $textColor4;
  }

  .error-message {
    @extend .SegoeUIRed14px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 18.188rem;

    .padd-left-1 {
      padding-left: 0.2rem;
    }
  }

  @media (max-width: $phone) {
    .error-message {
      width: 20rem;
    }
  }

  @media (min-width: $phone) and (max-width: $tablet) {
    .error-message {
      width: 21rem;
    }
  }

  @media (min-width: $tablet) and (max-width: $laptop) {
    .error-message {
      width: 28rem;
    }
  }

  @media (min-width: $laptop) and (max-width: $desktop) {
    .error-message {
      width: 15.188rem;
    }
  }

  @media (min-width: 1200px) {
    .error-message {
      width: 18.5rem;
    }
  }

  @media (min-width: 1500px) {
    .error-message {
      width: 21.5rem;
    }
  }

  @media (min-width: 1700px) {
    .error-message {
      width: 24.5rem;
    }
  }

  @media (min-width: 1800px) {
    .error-message {
      width: 26.5rem;
    }
  }

  @media (min-width: 1900px) {
    .error-message {
      width: 27.85rem;
    }
  }

  .top-place-holder {
    position: absolute;
    line-height: 1.4em;
    transform: translateY(-1.6em);
  }

  .read-only {
    cursor: default;
  }

  .remove-stepper {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .subtext {
    opacity: 0.6;
    @extend .SegoeUI14px;
    padding-left: $threepx;
  }

  .dark-subtext {
    @extend .SegoeUI14px;
    opacity: 1;
  }

  .left-icon {
    // top: 0.1em;
    left: 1.35em;
    position: absolute;
    z-index: 1;
    width: 1.5em;


    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  .right-icon {
    cursor: pointer !important;
    // top: 5%;
    right: 6%;
    position: absolute;
    z-index: 99 !important;
    width: 1.625em;

    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  .left-icon:focus-visible,
  .right-icon:focus-visible,
  .right-icon-2:focus-visible {
    width: 2em;
    outline: $onepx dashed $borderColor3 !important;
    position: absolute;
  }

  .right-icon-2 {
    cursor: pointer;
    top: 25%;
    right: 14%;
    position: absolute;
    z-index: 1;

    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }

}

.half-width {
  width: 75%;
  display: inline-block;
}

.full-width {
  width: 100%;
}

.center-align {
  text-align: center;
}

// Placeholder text size for mobile devices #522
@media (max-width: $tablet) {
  .form-control::placeholder {
    font-size: 14px !important;
  }
}

//Error message alignment
.msg-align {
  place-self: flex-start;
}

// Error message 
.error-text {
  flex-direction: column;
}

textarea {
  resize: none !important;
}