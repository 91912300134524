$onepx: 0.0625rem;
$twopx: 0.125rem;
$threepx: 0.1875rem;
$fourpx: 0.25rem;
$fivepx: 0.3125rem;
$sixpx: 0.375rem;
$sevenpx: 0.4375rem;
$eightpx: 0.5rem;
$ninepx: 0.5625rem;
$tenpx: 0.625rem;
$elevenpx: 0.6875rem;
$twelvepx: 0.75rem;
$thirteenpx: 0.8125rem;
$fourteenpx: 0.875rem;
$fifteenpx: 0.938rem;
$sixteenpx: 1rem;
$seventeenpx: 1.0625rem;
$eighteenpx: 1.125rem;
$nineteenpx: 1.1875rem;
$twentypx: 1.25rem;
$twentyOnepx: 1.3125rem;
$twentyTwopx: 1.375rem;
$twentyThreepx: 1.4375rem;
$twentyFourpx: 1.5rem;
$twentyFivepx:1.5625;
$twentySixpx: 1.625rem;
$twentySevenpx: 1.6875rem;
$twentyEightpx: 1.75rem;
$twentyNinepx: 1.8125rem;
$thirtypx: 1.875rem;
$hundredpx: 6.25rem;
$thirtyfourpx:2.125rem;
$thirtyfivepx:2.1875rem;
$thirtysixpx:2.25rem;
$thirtysevenpx:2.313;
$twohundredpx:12.5rem;
$halfpx:0.03125rem;
$fivehundredpx:31.25rem;
$pointninepx:0.05625rem;
$thousandpx:62.5rem;
$sevenfifty:46.875rem;
$sixhundred:37.5rem;
$sixtyonepx: 3.8125rem;
$fourtypx: 2.5rem;
$fourtytwopx:2.625rem;
$seventysixpx: 4.75rem;
