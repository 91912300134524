@import '~STYLES/colors.scss';
@import '~STYLES/fonts.scss';

/// This class is used to style VideoSource Component
/// @group call-dashboard
.video-source-panel {
    height: 100%;
    overflow: hidden auto;
    .video-header-pane {
        align-items: center !important;
    }
    .video-header {
        @extend .SegoeUI20pxBold;
        text-align: center;
        letter-spacing: 0em;
        opacity: 1;
    }

    .divider-margin {
        margin-right: 0.1rem !important;
        margin-left: 0.1rem !important;
    }
    .divider {
        opacity: 0.38;
        margin: 0.650rem 0;
    }

    .camera {
        @extend .SegoeUIWhite16pxBold;
    }
}
@media (min-width: $phone) and (max-width: 768px) {
    .video-header-pane {
        margin-right: 7rem !important;
        margin-left: 7rem !important;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .video-header-pane {
        margin-right: 11.5rem !important;
        margin-left: 11.5rem !important;
    }
}

@media (min-width: $phone) and (max-width: 1023px) {
    .alignment {
        text-align: center !important;
    }
}

// video panel scroll

 @media (min-width: $phone) {
  .scroll-panel-video {
    height: calc(100% - 4.3rem);
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
}
@media (max-width: $tablet) {
  .scroll-panel-video {
    height: calc(100% - 4.3rem);
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
}
