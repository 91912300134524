@import '~STYLES/colors.scss';
@import '~STYLES/fonts.scss';

.loading-backdrop {
  opacity: 0.8 !important; //Modified to resolve Bug#617
}

/// This class is used to style LoadingSpinner Component
.loading-spinner {
  .modal-content {
    border: 0;
    .loading-spinner-panel {
      height: 0vh;
      background-color: transparent;

      .loading-spinner-container {
        min-height: 90vh;
        display: flex;
        align-items: center;
        justify-content: center;
        .container-spin {
          .loader-text {
            @extend .SegoeUI22px;
            color: $textColor1;
            opacity: 1;
            line-height: 25px;
          }

          .lds-default {
            position: relative;
            width: 5rem;
            height: 5rem;
            margin: 0 auto;
            display: flex;
          }
          .lds-default div {
            position: absolute;
            width: 0.375rem;
            height: 0.375rem;
            background: $textColor4;
            border-radius: 50%;
            animation: lds-default 1.2s linear infinite;
          }
          .lds-default div:nth-child(1) {
            animation-delay: 0s;
            top: 2.313rem;
            left: 4.125rem;
          }
          .lds-default div:nth-child(2) {
            animation-delay: -0.1s;
            top: 1.375rem;
            left: 3.875rem;
          }
          .lds-default div:nth-child(3) {
            animation-delay: -0.2s;
            top: 0.688rem;
            left: 3.25rem;
          }
          .lds-default div:nth-child(4) {
            animation-delay: -0.3s;
            top: 0.438rem;
            left: 2.313rem;
          }
          .lds-default div:nth-child(5) {
            animation-delay: -0.4s;
            top: 0.688rem;
            left: 1.375rem;
          }
          .lds-default div:nth-child(6) {
            animation-delay: -0.5s;
            top: 1.375rem;
            left: 0.688rem;
          }
          .lds-default div:nth-child(7) {
            animation-delay: -0.6s;
            top: 2.313rem;
            left: 0.438rem;
          }
          .lds-default div:nth-child(8) {
            animation-delay: -0.7s;
            top: 3.25rem;
            left: 0.688rem;
          }
          .lds-default div:nth-child(9) {
            animation-delay: -0.8s;
            top: 3.875rem;
            left: 1.375rem;
          }
          .lds-default div:nth-child(10) {
            animation-delay: -0.9s;
            top: 4.125rem;
            left: 2.313rem;
          }
          .lds-default div:nth-child(11) {
            animation-delay: -1s;
            top: 3.875rem;
            left: 3.25rem;
          }
          .lds-default div:nth-child(12) {
            animation-delay: -1.1s;
            top: 3.25rem;
            left: 3.875rem;
          }
          @keyframes lds-default {
            0%,
            20%,
            80%,
            100% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.5);
            }
          }
        }
      }
    }
  }
  .modal-content {
    border: none;
  }
}
