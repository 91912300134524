@import "~STYLES/colors.scss";
@import "~STYLES/fonts.scss";
@import "~STYLES/pixelConvertConstants.scss";

/// This class is used to style DocumentFilters Component
/// @group dashboard
.menu{
    background-color: $backgroundColor2;
    border: $borderColor2;
    padding: 20px;
    border: 1px solid white;
    
    scroll-behavior: smooth;
    overflow-y: auto;
    height: calc(100%);
    font-size: 15px;
    
    h3 {
        font-weight: bold;
        font-size: 16px;
        color: $textColor1;
    }

    input {
        @extend .SegoeUIWhite16px;
        color: $textColor1;
        cursor: pointer !important;
        border: $onepx solid white;
        width: 100%;
        display: block;
        padding: 4px 4px 4px 8px;
        margin-top: 8px;
        margin-bottom: 8px;
        background: transparent;
    }
    
    .filter-group{
        margin:  10px 0px;
        color: $textColor1;

        p {
            margin-bottom: 4px;
        }
    }
    
    .checkbox{    
        display: flex;
        flex-direction: row;
        margin-top: 4px;
        margin-bottom: 4px;

        p {
            flex-grow: 1;
            margin: auto;
            padding-left: 8px;
            min-width: 200px;
        }

        input {
            margin: auto;
            width: auto;
            flex-shrink: 1;
            
        }

        button {
            width: auto;
            flex-shrink: 1;
            margin: auto;
            background-color: transparent;
            border: none;
        }
    }

    .tab {
        margin-left: 10px;
    }

    .buttons{    
        display: flex;
        flex-direction: row;
        margin-top: 20px;

        .action-buttons {
            flex-grow: 1;
            @extend .SegoeUI14Semibold;
            width: $hundredpx;
            height: $thirtyfivepx;
            display: flex;
            color: $textColor1;
            border-radius: 0em;
            margin-left:  $tenpx;
            margin-right:  $tenpx;
        }
        
        .cancel-button {
            flex-grow: 1;
        }
    }
}

