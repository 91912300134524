@import '~STYLES/colors.scss';
@import '~STYLES/fonts.scss';

/// This class is used to style Footer Component
/// @group footer
.footer-container {
  position: fixed !important;
  left: 0;
  width: 100%;
  height: 4.563rem !important;
  bottom: 0;

  .footer {
    height: 100%;
  }

  .icons-width {
    height: 100%;
    width: 5.125rem;
    margin: 0 0.63rem;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .icons-width:focus-visible {
    outline: $onepx dashed $borderColor3 !important;
  }

  .icons-set {
    width: 100%;
  }

  // Mobile Potrate mode
  .arrow {
    background-color: $mobileFooterArrowBackground;
  }

  @media (min-width: 320px) {
    .arrow {
      width: 3.875rem;
    }
  }

  // Media Quiries
  @media (max-width: $phone) {
    .icons-width {
      width: 25%;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  @media(min-width: $tablet) {
    .icons-width {
      width: 5.125rem;
    }

    .potrate-mode-icons {
      .icons-width {
        width: 20%;
        ;
      }
    }
  }

  // @media (max-width: $phone) and (orientation: landscape) {
  //   .icons-width {
  //     width: 10%;
  //   }
  // }

  @media (min-width: $phone) and (max-width: $tablet) {
    .icons-width {
      width: 16%;
      margin: 0%;
    }

    .potrate-mode-icons {
      .icons-width {
        width: 35%;
      }
    }
  }

  .footer-icon {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  @media (min-width: $tablet) and (max-width: $laptop) {
    .icons-width {
      width: 10.5%;
    }
  }

  @media (min-width: 481px) and (max-width: 768px) {
    .landscape-margin {
      margin-left: 1.5rem;
    }
  }

  @media (min-width: $tablet) and (orientation: portrait) {
    .potrate-mode-icons {
      .icons-width {
        width: 30%;
      }
    }
  }
}

.mobile-landscape-view {
  width: 7.91rem;

  .landscape-footer-container {
    position: fixed !important;
    right: 0;
    z-index: 1000;
    bottom: 0rem;
    background-color: transparent;
    text-align: -webkit-right;
  }

  .landscape-footer {
    width: 3.875rem;
    height: 4.563rem !important;
    background-color: $mobileFooterArrowBackground;
  }
}