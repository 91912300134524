@import '~STYLES/colors.scss';
@import '~STYLES/fonts.scss';
@import '~STYLES/pixelConvertConstants.scss';

/// This class is used to style ZoomPopover Component
/// @group call-dashboard
.zoom-popover {
  .popover {
    left: -1.225rem !important;
    top: -0.9rem !important;
    width: 13.875rem;
    height: 8.125rem;

    .popover-body {
      padding: $eighteenpx $thirteenpx;

      .range-value {
        @extend .SegoeUI14pxBoldWhite;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .rangeslider {
        margin-top: $tenpx  !important;
        height: 0.5rem !important;
        }

      .slider-horizontal {
        height: 0.2rem !important;
      }

      .label {
        @extend .SegoeUIWhite14px;
      }
    }
  }
}


@media (min-width: 300px) and (max-width: $tablet) and (orientation: portrait) {
  .zoom-popover {
    .popover {
      left: $seventeenpx  !important;
    }
  }
}

@media (min-width: $tablet) and (orientation: portrait) {
  .zoom-popover {
    .popover {
      left: -1.538rem !important;
    }
  }
}

@media (min-width: $tablet) and (max-width: $laptop) and (orientation: landscape) {
  .zoom-popover {
    .popover {
    left: -1.825rem !important;
    top: -1rem !important;
    }
  }
}
  