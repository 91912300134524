@import "~STYLES/colors.scss";
@import "~STYLES/fonts.scss";
@import "~STYLES/pixelConvertConstants.scss";

/// This class is used to style ErrorBoundary Component
.error-boundary {
  padding: 0;
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  .error-container {
    border-radius: $twopx;
    border: $halfpx solid $borderColor5;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $fivepx $tenpx;
    background-color: $backgroundColor12;
    width: $fivehundredpx;
  }

  .error-message {
    color: $errorColor;
    @extend .SegoeUI14px;
    font-size: $twelvepx;
  }
}
