@import "~STYLES/colors.scss";
@import "~STYLES/fonts.scss";
@import "~STYLES/pixelConvertConstants.scss";


/// This class is used to style Search Component
/// @group dashboard
.search-container {
    padding: 12px 12px 12px 12px;
    display: flex;
    flex-direction: row;

    input {
        @extend .SegoeUIWhite16px;
        color: $textColor1 !important;
        cursor: pointer !important;
        border: $onepx solid white !important;
        width: 100%;
        display: block;
        padding: 4px 4px 4px 40px;
        background: transparent url('../../../resources/assets/icon_search.svg') no-repeat 4px center;
        
        flex-shrink: 1;
        flex-grow: 1;
        
        &::placeholder {
            color: $textColor1;
        }
    }

    svg {

        flex-shrink: 1;
        flex-grow: 1;
        margin: auto;
        margin-left: 8px;
    }
}


.pill-button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px;
    gap: 7px;
    margin: 12px 12px 12px 12px;

    .pill-button {
        background-color: transparent;
        color: grey;
        border: 1px solid #ffffff42 !important;
        border-radius: 16px;
        outline: 2px solid white;
        padding: 3px 13px;
        margin: 0px 4px;

        .icon {
            display: inline;
            margin-left: 8px;
        }

        .icon > path {
            fill: #ffffff42;
        }
    }

    .active {
        color: $textColor1 !important;

        .icon > path {
            fill: white !important;
        }
    }
}

.page-buttons {
    display: flex;
    flex-direction: row;
    margin: 4px 4px;

    div.dots {
        position: relative;
        width: 10px;

        p {
            margin: 0px;
            margin-bottom: 5px;

            color: grey;
            position: absolute;
            bottom: 0;
        }
    }

    p.other-page {
        text-align: center;
        background-color: transparent;
        color: grey;
        border: 1px solid gray;
        min-width: 25px;
        margin: 4px 4px;
        padding: 4px 4px;
    }

    p.current-page {
        @extend p.other-page;
        color: $textColor1;
        border: 1px solid white !important;
    }
}

/* Style the button that is used to open and close the collapsible content */
.group-title {
    background-color: transparent;
    color: $textColor1;
    padding: 12px;
    text-align: left;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    p {
        flex-shrink: 1;
    }

    .pill-button-container {
        flex-grow: 1;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        padding: 0px;
        gap: 0px;
        margin: 0px 0px;
        margin-left: 12px;
        padding: 0px 0px;

        .pill-button {
            height: fit-content;
            padding: 3px 8px;
            margin: 0px 5px;
            font-size: 12px;

            .icon {
                margin-left: 3px;
            }
        }
    }
}

.group-content {
    padding: 0 12px;
    background-color: transparent;

    .collapsible {
        background-color: transparent;
        border: none;
    }

    .collapsible-content {
        padding: 0 12px;
        transition: max-height 0.2s ease-out;
        margin: 0px;
        background-color: transparent;
        padding: 0px !important;
        margin: 0px;

        p {
            text-overflow: ellipsis;
            word-wrap: break-word;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            line-height: 21px;
            max-height: 63px;
            white-space: normal;
        }
    }

    .collapsible-content-expand {
        max-height: attr(scrollHeight, 0);
        transition: max-height 0.2s ease-out;
        background-color: transparent;
        padding: 0px !important;
        margin: 0px;
        color: $textColor1;

        p {
            word-wrap: break-word;
            white-space: normal;
        }
    }
}

.group-content > *:not(:last-child) {
    margin-bottom: 15px;
}

.item {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    color: $textColor1;
    width: 100%;
}

.item-icon {
    flex: none;
    order: 1;
    flex-shrink: 0;
    flex-grow: 0;
    align-self: center;
    margin-right: 12px;
}


.item-details {
    padding: 0px, 0px, 0px, 12px;
    margin: 0px;
    flex: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    order: 1;
    color: $textColor1;
    flex-grow: 2;
    flex-shrink: 2;
    align-self: stretch;
    min-width: 0;
}

.item-action {
    order: 2;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: stretch;
    color: $textColor1;
    align-self: center;
    margin: auto;
    margin-left: 12px;
}

.details-major {
    font-weight: 700;
    font-size: 16px;
    margin: 0px;
    color: $textColor1;
    align-self: stretch;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.details-minor {
    font-weight: 400;
    font-size: 14px;
    margin: 0px;
    color: $textColor1;
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.file-search-match {
    color: $textColor1;
    margin: 0px;
    padding: 0px;
}

.file-search-match-field {
    color: $textColor1;
    margin: 0px;
    padding: 0px;
    display: inline;
    font-weight: bold;
}

.file-search-match-text {
    color: $textColor1;
    margin: 0px;
    padding: 0px;
    display: inline;
    word-break: break-all;
}

em {
    text-decoration: underline;
}

.scroll-view {
    scroll-behavior: smooth;
    overflow-y: auto;
    max-height: calc(100% - 14rem);
}