@import '~STYLES/colors.scss';
@import '~STYLES/fonts.scss';
@import '~STYLES/pixelConvertConstants.scss';

/// This class is used to style CustomPopover Component
/// @group custom-components
.custom-popover {
	.popover {
		width: 11.75rem;
		height: 17.75rem;
		top: -$fifteenpx  !important;
		left: -$fifteenpx  !important;
		border: $onepx solid $borderColor8;
		border-radius: 0;
		z-index: 101 !important;

		.popover-header {
			display: flex;
			justify-content: center;
			border: none !important;
			height: $thirtysevenpx;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			@extend .SegoeUI14pxBoldWhite;
		}

		.popover-body {
			padding: 1rem 1rem 0 1rem;
			color: $textColor1;
		}
	}
}