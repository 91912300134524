@import '~STYLES/colors.scss';
@import '~STYLES/fonts.scss';
@import '~STYLES/pixelConvertConstants.scss';

/// This class is used to style MeetingPopover Component
/// @group dashboard
.meetings-popover {
  .popover {
    width: auto !important;
    height: auto !important;
    left: 0 !important;
    top: 0 !important;
    max-width: 30rem !important;
    z-index: 2 !important;
    position: fixed !important;
  }

  .popover-body {
    padding: 1rem !important;

    .popup-text {
      @extend .SegoeUIWhite16px;
      font-size: $sixteenpx  !important;
      align-items: center !important;
      align-content: center !important;
      line-height: $nineteenpx;
    }
    .action-buttons {
      width: $hundredpx;
      height: $thirtyfivepx;
      display: flex;
      color: $textColor1;
      border-radius: 0em;
    }
    .cancel-button {
      margin-right: $tenpx;
    }
  }
}