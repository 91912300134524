@import "~STYLES/colors.scss";
@import "~STYLES/fonts.scss";
@import "~STYLES/pixelConvertConstants.scss";

// Desktop and portrait mode
/// This class is used to style NlpPopover Component
/// @group footer
.nlp-popover {
    padding: 0px !important;
    height: inherit;
}

// Landscape mode
.landscape-nlp {

    .popover,
    .bs-popover-auto {
        left: $twelvepx !important;
        height: 11.89rem !important;
        width: 22.645625rem !important;
        max-width: 400px;
    }

    .nlp__menu {
        width: 8.6875rem !important;
    }
}

// Dropdowns
.custom-select {
    width: -webkit-fill-available;
}

//search bar
.nlp__control,
.nlp__control--is-focused {
    height: 1rem;
    border-radius: $fourpx;
    border: $onepx solid $borderColor3 !important;
    cursor: pointer;
    color: $white !important;
    align-content: center;
}

.nlp__single-value {
    color: $white !important;
}

// dropdown list container
.nlp__menu-list {
    max-height: $seventysixpx !important;
    padding: 0px !important;
    align-content: center;
}

// dropdown options list menu
.nlp__menu {
    border: $onepx solid $borderColor3;
    margin-top: 0% !important;
    border-radius: $fourpx;
    z-index: 999 !important;
    width: auto !important;
    align-self: center;
    width: 8.15rem !important;
    align-content: center;
}

// options
.nlp__option {
    color: $white !important;
    height: 33px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-content: center;
}

// hover on an option
.nlp__option:hover {
    cursor: pointer !important;
    height: 33px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-content: center !important;
}

//search input text
.nlp__input-container {
    @extend .SegoeUIWhite16px;
    color: $white !important;
    cursor: pointer !important;
    border: $onepx soild $borderColor3 !important;

    input[type=text]:focus {
        border: none !important;
    }
}


// Captions 
.captions {
    @extend .SegoeUIWhite14px;
    line-height: $nineteenpx;
}

.nlp-divider {
    height: 0px;
    opacity: 0.38;
    margin-top: 1rem !important;
    width: 100%;
}

.disable-ui {
    pointer-events: none !important;
    opacity: 0.5;
}

@media (min-width: $tablet) and (orientation: portrait) {
    .nlp-popover {
        .popover {
            left: -1.538rem !important;
        }
    }
}