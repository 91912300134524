
@import "~STYLES/colors.scss";
@import "~STYLES/fonts.scss";
@import "~STYLES/pixelConvertConstants.scss";

.custom-modal {
    background:$backgroundColor3 0% 0% no-repeat padding-box;
    box-shadow: 0rem $threepx $fifteenpx $customModalBoxShadow;
    opacity: 1;
    .modal-body{
        .dot-spinner-icon {
                animation: rotate 1.2s infinite linear;
        
                @keyframes rotate {
                    from {
                        transform: rotate(0deg);
                    }
        
                    to {
                        transform: rotate(360deg);
                    }
                }
            }
        max-width: $fivehundredpx;
        .modal-header{
            @extend .SegoeUI18pxSemiBold;
            letter-spacing: $pointninepx;
            color:  $white ;
            text-transform: capitalize;
            opacity: 1;
            border: none;
        }
        .modal-body-contents{
            @extend .SegoeUIWhite14px;
            text-align: left;
            letter-spacing: 0rem;
            color:  $white ;
            opacity: 1;
        }
        .action-buttons{
            width: $hundredpx;
            height: $thirtyfivepx;
            display: flex;
            color: $textColor1;
            border-radius: 0em;
        }
        .cancel-button{
            margin-right: $tenpx;
        }
        .center-items{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
    