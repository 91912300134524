@import '~STYLES/colors.scss';
@import '~STYLES/fonts.scss';
@import '~STYLES/pixelConvertConstants.scss';

/// This class is used to style RangeSlider Component
/// @group custom-components
.range-slider-component {
  .stroke-component {
    display: flex;
    align-items: baseline;
  }

  // Range slider
  .slider-horizontal {
    height: 0.188rem !important;

    .rangeslider-horizontal .rangeslider__fill {
      background-color: $checkedBtnSwitch;
    }

    .rangeslider-horizontal .rangeslider__handle {
      height: $twelvepx  !important;
      width: $twelvepx  !important;
    }

    .rangeslider-horizontal .rangeslider__handle::after {
      content: none !important;
      height: 0;
      width: 0;
    }

    .rangeslider {
      background-color: $sliderSwitchBackground;
      height: 0.3rem !important;
    }

    .rangeslider-horizontal {
      height: $fivepx;
      border-radius: $tenpx;
    }
  }
}