@import "~STYLES/colors.scss";
@import "~STYLES/fonts.scss";
@import "~STYLES/pixelConvertConstants.scss";

.custom-modal {
    background: $backgroundColor3 0% 0% no-repeat padding-box;
    box-shadow: 0rem $threepx $fifteenpx $customModalBoxShadow;
    opacity: 1;

    .modal-body {
        max-width: $fivehundredpx;

        .modal-header {
            @extend .SegoeUI18pxSemiBold;
            letter-spacing: $pointninepx;
            color: $white;
            text-transform: capitalize;
            opacity: 1;
            border: none;

            .header1 {
                @extend .SegoeUI18pxSemiBold;
                line-height: $twentyFourpx;
            }
        }

        .modal-body-contents {
            @extend .SegoeUIWhite16px;
            text-align: left;
            letter-spacing: 0rem;
            color: $white;
            opacity: 1;
        }

        .action-buttons {
            @extend .SegoeUI14Semibold;
            width: $hundredpx;
            height: $thirtyfivepx;
            display: flex;
            color: $textColor1;
            border-radius: 0em;
        }

        .cancel-button {
            margin-right: $tenpx;
        }

        .sub-text1 {
            @extend .SegoeUI18pxBold;
            padding-left: 0.5rem;
        }

        .message-text {
            @extend .SegoeUIWhite16px;
            line-height: $twentypx;
        }

        .center-content {
            text-align: -webkit-center !important;
        }
        .justify-message-text{
            text-align: justify;
        }
    }
}


.react-responsive-modal-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}