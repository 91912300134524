@import '~STYLES/colors.scss';
@import '~STYLES/fonts.scss';

/// This class is used to style AudioSource Component
/// @group call-dashboard
.audio-source-panel {
	height: 100%;
	overflow: hidden auto;

	.audio-header-pane {
		align-items: center !important;
	}

	.audio-header {
		@extend .SegoeUI18pxBold;
	}

	.audio-divider {
		margin-right: 0.01rem !important;
		margin-left: 0.3rem !important;
	}

	.divider-margin {
		margin-right: 0.1rem !important;
		margin-left: 0.1rem !important;
	}

	.divider {
		opacity: 0.38;
		margin: 0.650rem 0;
	}

	.sub-header {
		@extend .SegoeUIWhite16pxBold;
	}

	.disabled {
		opacity: 0.4;
	}
}

@media (min-width: $phone) and (max-width: 768px) {
	.divider-margin {
		margin-right: 0.5rem !important;
		margin-left: 0.6rem !important;
	}

	.audio-header-pane {
		margin-right: 7rem !important;
		margin-left: 7rem !important;
	}
}

@media (min-width: 769px) and (max-width: 1023px) {
	.audio-header-pane {
		margin-right: 11.5rem !important;
		margin-left: 11.5rem !important;
	}
}

@media (max-width: 1023px) {
	.audio-alignment {
		text-align: center !important;
	}
}

// audio panel scroll
@media (min-width: $phone) {
	.scroll-panel {
		max-height: calc(100% - 6rem);
		overflow-y: auto !important;
		overflow-x: hidden !important;
	}
}

@media (max-width: $tablet) {
	.scroll-panel {
		max-height: calc(100% - 6rem);
		overflow-y: auto !important;
		overflow-x: hidden !important;
	}
}