@import '~STYLES/colors.scss';
@import '~STYLES/fonts.scss';

@import '~STYLES/pixelConvertConstants.scss';

/// This class is used to style Login Page
/// @group login
.login-layout {
    height: 100%;

    .sub-component {
        height: 100%;
        position: relative;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow-y: auto;

        .login-container-layout {
            height: 100%;
            align-items: center;
            display: flex;
            flex-direction: column;

            .login-container {
                .logo-text {
                    align-items: center;
                    display: flex;
                    justify-content: center;
                }

                .text-field-component {
                    margin-top: 0.9em;

                    // Login textfields for desktop and mobile
                    .text-desktop {
                        @extend .SegoeUI20px;
                        height: 3.5625rem;
                        padding-bottom: 0.695rem;
                    }

                    .text-mob {
                        @extend .SegoeUI14px;
                        height: 2.8125rem;

                        &[data-left-icon="true"] {
                            padding-left: 3em;
                        }

                        padding-bottom: 0.45rem;
                    }
                }

                .login-btn {
                    border-radius: 0em;
                    border: none;
                    background-color: $loginButtonColor1;
                    color: $textColor1;
                }

                .btn-primary,
                .btn-primary:hover {
                    background-color: $loginButtonColor1;
                    border: none;
                }

                .btn-check:focus + .btn-primary,
                .btn-primary:focus {
                    box-shadow: none;
                }

                .forget-password {
                    align-items: flex-end;
                    display: flex;
                    justify-content: flex-end;
                    opacity: 1;

                    .forgot-text {
                        @extend .SegoeUIWhite16px;
                        color: $textColor1;
                        text-decoration: none;
                    }
                }

                .remember-password {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    @extend .SegoeUIWhite18px;
                    padding-right: 0rem;

                    .padding-left-rem {
                        padding-left: 0.75rem;
                        padding-right: 0rem;
                    }

                    @media (max-width: $phone) {
                        .padding-left-rem {
                            padding-left: 1rem;
                            padding-right: 0rem;
                        }
                    }
                }

                .react-switch {
                    vertical-align: middle;
                    margin-left: 0.25rem;
                    margin-top: 0.125rem;
                    opacity: 1;
                    z-index: 1;
                }

                .react-switch-handle {
                    transform: translateX(0.875rem);
                    border: 0;
                    outline: 0;
                }

                .checked-button {
                    opacity: 1;

                    .react-switch-bg {
                        background: $checkedBtnSwitch !important;
                    }

                    .react-switch-handle {
                        background: $backgroundColor14 !important;
                        box-shadow: none !important;
                    }
                }

                .unchecked-button {
                    .react-switch-bg {
                        background: $backgroundColor8 !important;
                    }

                    // .react-switch-handle {
                    //   background: $backgroundColor5 !important;
                    //   box-shadow: 0rem $onepx $threepx $uncheckedBtnSwitch !important;
                    //   opacity: 1 !important;
                    // }
                }

                // #email:focus,
                // #password:focus {
                //   border-color: #86b7fe !important;
                //   box-shadow: 0 0 0 0.25rem $textBoxShadowColor !important;
                // }

                //adding this below css block in globalthemes:
                // #email:-webkit-autofill,
                // #password:-webkit-autofill {
                //   -webkit-box-shadow: 0 0 0px 62.5rem $backgroundColor2 inset !important;
                //   box-shadow: 0 0 0px 62.5rem $backgroundColor2 inset !important;
                //   -webkit-text-fill-color: $textColor4 !important;
                // }
            }

            // .text-field-component input:-webkit-autofill {
            //   box-shadow: 0 0 0px 1000px $backgroundColor2 inset;
            //   -webkit-text-fill-color:  $textColor4 ;
            // }
            .login-with-sso {
                @extend .SegoeUIWhite26px;
                @extend .align-center;
                color: $textColor1;
                opacity: 1;
            }

            .sign-in-options {
                @extend .SegoeUIWhite16px;
                @extend .align-center;
                color: $textColor1;
                opacity: 1;
            }

            //login btn
            .login-btn-desktop {
                height: 3.375rem;
                @extend .SegoeUI18pxSemiBold;
                line-height: $twentyFourpx !important;
            }

            .login-btn-mob {
                height: 2.9375rem;

                @extend .SegoeUIWhiteSemi16px;
                line-height: $twentyOnepx;
            }

            .forgot-text:focus-visible {
                outline: $onepx dashed $borderColor3 !important;
            }

            // ---------Change password styles----------
            .change-password-text {
                @extend .SegoeUIWhite26px;
                line-height: 2.1875rem !important;
            }

            .password-text1 {
                @extend .SegoeUI20pxSemiBold;
                text-align: -webkit-center;
                line-height: $twentypx !important;
            }

            .password-text2 {
                @extend .SegoeUIWhite18px;
                text-align: -webkit-center;
                line-height: $twentypx !important;
            }

            // Cancel and submit button on change password page
            .btn {
                @extend .SegoeUI18pxSemiBold;
                height: 3.125rem;
            }

            .mob-button {
                @extend .SegoeUIWhiteSemi16px;
                height: 2.9375rem;
            }

            // Textfields on change password page
            .password-field {
                @extend .SegoeUI20px;
                height: 3.5625rem;
            }

            .password-field-mob {
                @extend .SegoeUIWhite16px;
                height: 2.8125rem;
            }
        }
    }

    // Login page has no bottom bar, so reset the toast margin
    .Toastify__toast-container--bottom-center {
        margin-bottom: 0 !important;
    }
}