@import "~STYLES/colors.scss";
@import "~STYLES/fonts.scss";
@import "~STYLES/pixelConvertConstants.scss";

/// This class is used to style FilterSelect Component
/// @group dashboard
.filter-select {
    height: 2.5rem;
    background-color: transparent;

    //search bar
    .filter-search__control,
    .filter-search__control--is-focused {
        height: 2.5rem;
        border-radius: $fourpx;
        background-color: transparent;
        border: $onepx solid $borderColor3;
        cursor: pointer;

        //search input text
        .filter-search__input-container {
            @extend .SegoeUIWhite16px;
            color: $white;
            cursor: pointer;

            input[type=text]:focus {
                border: none;
            }

            //text typed as an input
            .filter-search__input {
                border: none;
                cursor: pointer;
            }
        }

        //search bar placeholder
        .filter-search__placeholder {
            background-color: transparent;
            cursor: pointer;
            color: $white;
            opacity: 0.8;
        }

        .filter-search__indicators {
            cursor: pointer;
        }

    }

    // dropdown options list menu
    .filter-search__menu {
        background-color: $backgroundColor2;
        border: $onepx solid $borderColor3;
        margin-top: 0%;
        border-radius: $fourpx;
        z-index: 999;

        //dropdownlist container
        .filter-search__menu-list {
            max-height: 21.875rem;

            // options
            .filter-search__option {
                background-color: transparent;
                color: $white;
                height: 3.875rem;
            }

            // hover on an option
            .filter-search__option:hover {
                background-color: $dropdown-hover;
                height: 3.875rem;
                cursor: pointer;
            }
        }
        .filter-search__group {
            padding: 0rem;
            border-bottom: 0.03rem solid $parDivBorder;
        }
    }
}