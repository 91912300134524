@import './colors.scss';

@import '~STYLES/pixelConvertConstants.scss';
/*****************************Font size for different resolutions according to the base font*********************/
// @media only screen and (max-width: 1024px) {
//   html {
//     font-size: 12px !important;
//   }
// }

// @media only screen and (min-width: 1024px) and (max-width: 1280px) {
//   html {
//     font-size: 14px !important;
//   }
// }

// @media only screen and (min-width: 1280px) and (max-width: 1366px) {
//   html {
//     font-size: 16px !important;
//   }
// }

// @media only screen and (min-width: 1366px) and (max-width: 1920px) {
//   html {
//     font-size: 18px !important;
//   }
// }

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.align-items-end-center {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.align-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.align-vertical-center {
    display: flex;
    align-items: center;
}

.align-horizontal-center {
    display: flex;
    justify-content: center;
}

.align-vertically-end {
    display: flex;
    align-items: flex-start;
}

.align-start {
    display: flex;
    align-items: flex-start;
}

.align-end {
    display: flex;
    align-items: flex-end;
}

.align-horizontal-end {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.align-vertical-end {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.align-horizontal-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.vertical-align-middle {
    vertical-align: middle;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default !important;
}

.block {
    display: block;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.primary-button:focus-visible {
    outline: $onepx dashed $borderColor3 !important;
}

.secondary-button:focus-visible {
    outline: $onepx dashed $borderColor3 !important;
}

*:focus {
    outline: none !important;
}

.align-right {
    text-align: end !important;
}

.self-align-center {
    align-self: center !important;
}

.disabled-state {
    opacity: 0.4;
    pointer-events: none;
}

@media (min-width: $phone) {
    .landscape-side-margins {
        margin-left: 9.5rem !important;
        margin-right: 9.5rem !important;
    }
}

.button-full-width {
    width: 100%;
}

.no-horizontal-scroll {
    overflow-x: hidden !important;
}

.flex-height {
    flex: 1;
    height: 100%;
}

.abs-position {
    position: absolute;
}
#text-tele-len{
    display: block;
    width: max-content;
    visibility:hidden;
    position: absolute;
}