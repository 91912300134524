@import '~STYLES/colors.scss';
@import '~STYLES/fonts.scss';
@import '~STYLES/pixelConvertConstants.scss';

/// This class is used to style ColorPopover Component for Telestration/Annotations
/// @group call-dashboard 
.color-popover {
  .popover {
    width: 26.438rem;
    max-width: 31.25rem;
    top: $fivepx;
    left: $twelvepx !important;
    border: $onepx solid $borderColor10;
    border-radius: 0;

    .popover-body {
      opacity: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0.35rem $fifteenpx;

      .color-box {
        width: $fourtypx;
        height: $fourtypx;
        margin: 0.1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .color-drop {
          border-radius: 0 50% 50% 50%;
          border: $twopx solid $borderColor9;
          width: 1.05rem;
          height: 1.05rem;
          opacity: 1;
          transform: rotate(45deg);
        }
      }
    }
  }
}

/// This class is used to style TelestrationPopover Component
/// @group call-dashboard
.telestration-popover {
  .popover {
    .popover-body {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .telestration-icon {
        @extend .align-center;
        cursor: pointer;
        padding: 0.563rem;
      }

      .disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      .color-drop {
        border-radius: 0 50% 50% 50%;
        border: $twopx solid $borderColor9;
        width: 1.05rem;
        height: 1.05rem;
        opacity: 1;
        transform: rotate(45deg);
      }

      .divider-margin {
        margin-right: 0.1rem !important;
        margin-left: 0.1rem !important;

        .divider {
          height: 0px;
          opacity: 0.38;
          margin: 0.35rem 0 !important;
          border-bottom: 0.06rem solid $borderColor4 !important;
        }
      }

      .rangeslider {
        margin-top: $twelvepx !important;
      }
    }
  }

  .rangeslider-horizontal .rangeslider__handle::after {
    content: none !important;
    height: 0;
    width: 0;
  }

  .rangeslider {
    background-color: $sliderSwitchBackground;
  }

  .rangeslider-horizontal {
    height: $fivepx;
    border-radius: $tenpx;
  }
}


@media (min-width: 300px) and (max-width: $tablet) {
  .telestration-popover {
    .popover {
      width: 11.75rem;
      height: 15.125rem;
      left: -$twelvepx !important;
      top: -$sixteenpx !important;

      .popover-body {
        padding: 0 1rem;

        .divider-margin {
          .divider {
            margin: 0.1rem 0 !important;
          }
        }
      }
    }
  }

  .color-popover {
    .popover {
      width: 19.938rem;

      .popover-body {
        padding: 0.443rem 0.591rem !important;

        .color-box {
          height: 1.55rem !important;
          width: 1.55rem !important;
          margin: 0.25rem !important;
        }
      }
    }

  }
}

@media (min-width: $tablet) and (orientation: portrait) {
  .telestration-popover {
    .popover {
      left: -1.538rem !important;
    }
  }
}