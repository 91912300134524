
@import "~STYLES/colors.scss";
@import "~STYLES/fonts.scss";
@import "~STYLES/pixelConvertConstants.scss";

/// This file is used to style Settings menu
/// @group call-dashboard
.custom-modal-settings {
    overflow-y: hidden;
     margin-top: 1%;
     max-height: 72%;

    .settings-modal-body{
        max-width: 100% !important;
        .settings{
            height: 50vh;
            width: 42rem;
        }

        // 649 for small ipad/tablets
        @media (min-width : 600px) and (orientation: portrait) {
            .settings{
                height: 50vh;
                width: 42rem;
            }
        }

        // 649 for laptop
        @media (min-width : 800px) and (orientation: portrait) {
            .settings{
                width: 46rem !important;
                height: 38vh !important;
            }
        }

        @media (min-width : 1024px) { // 649
            .settings{
                width: 52rem !important;
                height: 68vh;
            }
        }

        // Big tabs 649
        @media (min-width : 1024px) and (min-height : 1360px) and (orientation: landscape) {
            .settings {
                width: 50rem !important;
                height: 40vh;
            }
        }

        // Desktop, MAC #649
        @media (min-width : 1180px) {
            .settings{
                height: 68vh;
            }
        }

        @media (min-width : 1500px) {
            .settings{
                height: 65vh;
                width: 54rem !important;
            }
        }

        @media (min-width : 1800px) {
            .settings{
                height: 58vh;
                width: 56rem !important;
            }
        }

        .left-panel {
            border-right: 0.00001rem solid $borderColor3;
            max-width: fit-content;
            margin-bottom: 10%;
            width: inherit;
            padding-right: 0.75rem;
        }
        .modal-header{
            text-transform: capitalize;
            opacity: 1;
            border: none;
        }
        .header-style{
            @extend .SegoeUI20pxBold;
        }
        .modal-body-contents{
            text-align: left;
            opacity: 1;
            height: 100%;
        }
        .action-buttons{
            @extend .SegoeUI14Semibold;
            width: $hundredpx;
            height: $thirtyfivepx;
            display: flex;
            color: $textColor1;
            border-radius: 0em;
        }
        .cancel-button{
            margin-right: $tenpx;
        }
        .close-btn{
            padding-left: 98%;
        }
        .btn:focus {
            outline: none;
            box-shadow: none;
          }
        .settings-tabs {
            width: 100% !important;
            color: $textColor1 !important;
            background-color: transparent;
            text-align: left;
            border: none !important;
        }
        .inherit-width-tabs {
            width: inherit !important;
        }
    }
}

// profile text fields
.my-profile{
  .disabled-field {
        color: $textColor2 !important;
        opacity: 0.9 !important;
    }

    .text-field-component {
        @extend .SegoeUIWhite16px;
        
        input[type=text] {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

  .sidebar-scroll {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    max-height: calc(100% - 2rem);
  }

  .sidebarScrollOnLandscape{
      overflow: hidden !important;
  }

  @media (min-width: $tablet) {
    .textbox-profile{
        height: $thirtypx;
        width: 20.6875rem;
    }
  }

  @media (max-width: $laptop) {
    .mob-profile-textbox{
        height: $fourtypx;
    }
  }
}

.uri-header{
    @extend .SegoeUIWhite16pxBold;
}

//settingsChange
.header-label {
    @extend .SegoeUI17pxBoldWhite;
    text-align: center;
    letter-spacing: 0em;
    opacity: 1;
  }

.font-regular {
    @extend .SegoeUIWhite14px;
}

.text-field-component {
    @extend .SegoeUIWhite16px;
}

.font-semi {
    @extend .SegoeUI14Semibold;
    color: $textColor1;
}

// Bandwidth control
.bandwidth-value {
    @extend .SegoeUI10pxBold;
    background-color: $backgroundColor6;
    border-radius: 0.25rem;
    color: $textColor1;
}

.bandwidth-unit {
    @extend .SegoeUI12px;
    color: $textColor1;
}

.bandwidth-unit-mobile {
    @extend .SegoeUI14px;
    color: $textColor1;
}

.bandwidth-value-mobile{
    @extend .SegoeUI14px;
    background-color: $backgroundColor6;
    border-radius: 0.25rem;
    color: $textColor1;
}

// Range slider
.rangeslider {
    width: -webkit-fill-available !important;
    height: 0.25rem !important; 
    background-color: $sliderSwitchBackground !important;
}

.rangeslider-horizontal .rangeslider__fill {
    background-color: $checkedBtnSwitch !important;
}

.rangeslider-horizontal .rangeslider__handle {
    height: $twelvepx  !important;
    width: $twelvepx  !important;
}

.rangeslider-horizontal .rangeslider__handle::after {
    content: none !important;
    height: 0;
    width: 0;
}

.rangeslider-horizontal {
    height: $fivepx;
    border-radius: $tenpx;
}

// sip addr ellipsis for tablet portrait
@media (max-width: $tablet) and (orientation: portrait) {
    .tab-uri {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

// General 
.about {
    @extend .SegoeUIWhite16pxBold;
    line-height: $twentyOnepx;
}

.version {
    @extend .SegoeUI14px;
    color: $textColor1;
    line-height: $nineteenpx;
}

.eula-link, .eula-link:hover {
    @extend .SegoeUI14px;
    color: $linkColor;
    line-height: $nineteenpx;
    text-decoration: none;
    cursor: pointer;
    width:fit-content !important;
}

// Settings popup on different devices (#649)
@media (min-width: $laptop) and (orientation: landscape) {
    .custom-modal-settings {
        max-width: 70%;

        .uri-width {
            width: 19rem !important;
        }
        .uri-width-desktop {
            width: 25rem !important;
        }
    }
}

@media (min-width: 1600px) and (orientation: landscape) {
    .custom-modal-settings {
        max-width: 60% !important;

        .uri-width {
            width: 22rem !important;
        }
        .uri-width-desktop {
            width: 25rem !important;
        }
    }
}

@media (max-width: 1023px) {
    .custom-modal-settings {
        max-width: 90% !important;

        .uri-width {
            width: 17rem !important;
        }
    } 
}

//uri width for mobile portrait mode
@media (max-width: $phone) and (orientation: portrait) {
    .uri-mobile {
        width: 17rem !important;
    }
}
//uri width for mobile landscape mode
@media (max-height: $phone) and (orientation: landscape) {
    .uri-mobile {
        width: 22rem !important;
    }
}

.switch-calls {
    display: inline-block !important;
    text-align: end !important;
}

.settings-scroll-landscape {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    max-height: calc(100% - 4rem);
  }