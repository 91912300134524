@import '~STYLES/colors.scss';
@import '~STYLES/fonts.scss';
@import '~STYLES/pixelConvertConstants.scss';

.custom-checkbox{
    .form-check-input[type=checkbox] {
        border: $threepx solid white;
        height: $twentypx;
        width: $twentypx;
    }
    .form-check-input:checked{
        background-color: transparent;
    }
    .form-check-input:focus{
        box-shadow: none;
    }
    .form-check-input {
        background-color: transparent;
    }
}