@import '~STYLES/colors.scss';
@import '~STYLES/fonts.scss';
@import "~STYLES/pixelConvertConstants.scss";
@import "~STYLES/globalStyles.scss";
//participants list ui

/// This class is used to style Participants Component
/// @group call-dashboard
.participant-list-container {
  height: 85%;

  .participant-list-panel {
    height: 100%;
    overflow: hidden auto;
  }

  .participant {
    @extend .SegoeUIWhite14px;
    // margin-left: 0.3rem;
  }

  .participants-header {
    @extend .SegoeUI20pxBold;
    text-align: center;
    letter-spacing: 0em;
    opacity: 1;
  }

  .search {
    border: $onepx solid $searchBorder !important;
    border-radius: $fourpx !important;
    opacity: 1;
    color: $textColor1 !important;
    background-color: transparent;
    font-size: 0.9375em !important;
  }

  .search-bar {

    .text-field-component .form-control,
    .text-field-component :focus {
      height: 2.5rem; //30px;

      &[data-left-icon='true'] {
        padding-left: 2.9em;
      }
    }

    .left-icon {
      top: 22%; //10%;
      left: 3%;
      opacity: 0.4;
    }

    @media (min-width: 320px) and (max-width: 1024px) {

      .text-field-component .form-control,
      .text-field-component :focus {
        height: 2.5rem;

        &[data-left-icon='true'] {
          padding-left: 2.9em;
        }
      }

      .left-icon {
        top: 22%;
        left: 3%;
        opacity: 0.4;
      }
    }
  }

  .participant-list-pane {
    max-height: calc(100% - 2rem);
    height: 100%;
    overflow: hidden auto;
  }

  .participant-list-padding {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }

  @media (min-width: 320px) and (max-width: 1024px) {
    .participant-list-padding {
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
    }
  }

  @media (min-width: 1800px) {
    .participant-list-padding {
      padding-right: 0.5rem !important;
    }

    .iconPositionForBigScreen {
      display: flex !important;
      justify-content: flex-end !important;
    }
  }


  // .participant-list{
  //   .margin {
  //       margin-right: 0.5rem;
  //       margin-left: 0.5rem;
  //     }
  //   .c-name {
  //       @extend .SegoeUIWhite15px;
  //       text-align: left;
  //       letter-spacing: 0em;
  //       padding: 0.5em;
  //       opacity: 0.5;
  //     }
  // }

  .c-name {
    @extend .SegoeUIWhite15px;
    text-align: left;
    letter-spacing: 0em;
    padding: 0.5em;
    opacity: 0.5;
  }

  .divider {
    // width: 319px;
    height: 0px;
    opacity: 0.38;
    margin-top: 1rem !important;
  }

  .participant-divider {
    height: 0rem;
    border-bottom: 0.03rem solid $parDivBorder;
    opacity: 1;
  }

  .divider-margin {
    margin-right: 0.1rem !important;
    margin-left: 0.1rem !important;
  }

  button#mute-button {
    border-radius: 0.1875em !important;
    min-width: max-content;
  }

  .mute-text {
    @extend .SegoeUI12pxSemi;
    text-align: center;
    letter-spacing: 0em;
    text-transform: capitalize;
    opacity: 1;
  }

  .short-name {
    background-color: $shortnamegb;
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
  }

  .short-name-text {
    @extend .SegoeUI12pxSemi;
    color: $textColor3;
  }

  .enabled-icons {
    height: 1.5em;
    margin-right: 0.6rem;
    padding-top: 0.2rem;
    cursor: pointer;
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .margin-participant {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .mr {
    margin-right: 0.5rem;
  }

  .participants-header {
    @extend .SegoeUI20pxBold;
    text-align: center;
    letter-spacing: 0em;
    opacity: 1;
  }

  .sub-header {
    @extend .SegoeUI14pxBoldWhite;
  }
}

@media (min-width: 320px) and (max-width: 1023px) {
  .alignment {
    text-align: center !important;
  }
}

@media (min-width: $phone) and (max-width: 768px) {
  .participant-list {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
    justify-content: center;
  }

  .header-pane {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
    justify-content: center;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .participant-list {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
    justify-content: center;
  }


  .header-pane {
    margin-right: 10.1rem !important;
    margin-left: 10.1rem !important;
    justify-content: center;
  }
}

// Participants title in ipad landscape
// Commented while responsiveness changes 
// @media (width: 1024px) and (orientation: landscape) {
//   .participant-title {
//     margin-top: 4.5rem !important;
//   }
// }