@import '~STYLES/colors.scss';
@import '~STYLES/pixelConvertConstants.scss';

.form-radio-input[type=radio] {
    appearance: none;
    width: 1.041875rem;
    height: 1.041875rem;
    border: $twopx solid rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    background-clip: content-box;
    padding: 0.15625rem;
}

.form-radio-input[type=radio]:checked {
    border: $twopx solid;
}