@import "~STYLES/colors.scss";
@import "~STYLES/fonts.scss";
@import "~STYLES/pixelConvertConstants.scss";

.image-consent-modal-wrapper {
  .react-responsive-modal-container {
    height: calc(100% - 5.526rem);
    display: flex !important;
    justify-content: center !important;
    align-items: flex-end !important;
  }
}

.image-transfer-modal-wrapper {
  .react-responsive-modal-container {
    height: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

.image-capture-modal {
  width: 412px;
  height: 154px;
  padding: 0;
  overflow: hidden;
  background: $backgroundColor3 0% 0% no-repeat padding-box;
  box-shadow: 0rem $threepx $fifteenpx $customModalBoxShadow;
  opacity: 1;

  .modal-body {
    max-width: $fivehundredpx;
    padding: 1.375rem 1.063rem 1.063rem 1.864rem;

    .modal-header {
      letter-spacing: $pointninepx;
      color: $white;
      text-transform: capitalize;
      opacity: 1;
      border: none;

      .header {
        @extend .SegoeUI18pxSemiBold;
        line-height: $twentyOnepx;
      }
    }

    .modal-body-contents {
      @extend .SegoeUIWhite14px;
      text-align: left;
      letter-spacing: 0rem;
      color: $white;
      opacity: 1;

    }

    .message-text {
      @extend .SegoeUIWhite14px;
      line-height: $nineteenpx;
    }

  }

  .action-buttons {
    @extend .SegoeUI14Semibold;
    width: $hundredpx;
    height: $thirtyfivepx;
    display: flex;
    color: $textColor1;
    border-radius: 0em;
  }

  .cancel-button {
    margin-right: $tenpx;
  }
}

.transfer-modal {
  width: 305px;
  height: 189px;

  .progress {
    height: 0.438rem;
    background: $backgroundColor9 0% 0% no-repeat padding-box;
    border-radius: 0;
    padding: 0;

    .progress-bar {
      background: $backgroundColor10 0% 0% no-repeat padding-box;
    }
  }

  .modal-body {
    max-width: $fivehundredpx;
    padding: 1.375rem 1.063rem 1.063rem 1.864rem;

    .modal-header {
      letter-spacing: $pointninepx;
      color: $white;
      text-transform: capitalize;
      opacity: 1;
      border: none;

      .header {
        @extend .SegoeUI18pxSemiBold;
        line-height: $twentyOnepx;
      }
    }

    .modal-body-contents {
      @extend .SegoeUIWhite14px;
      text-align: left;
      letter-spacing: 0rem;
      color: $white;
      opacity: 1;

    }

    .message-text {
      @extend .SegoeUIWhite14px;
      line-height: $nineteenpx;
    }

  }

  .action-buttons {
    @extend .SegoeUI14Semibold;
    width: $hundredpx;
    height: $thirtyfivepx;
    display: flex;
    color: $textColor1;
    border-radius: 0em;
  }

  .cancel-button {
    margin-right: $tenpx;
  }
}