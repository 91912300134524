@import '~STYLES/colors.scss';
@import '~STYLES/fonts.scss';
@import '~STYLES/pixelConvertConstants.scss';

/// This class is used to style IlluminationPopover Component
/// @group call-dashboard
.illumination-popover {
  .popover {
    width: 11.688rem !important;
    height: 15.188rem !important;
    left: -$seventeenpx  !important;
    top: -$fourteenpx  !important;
  }

  .popover-body {
    padding: 0 !important;

    .flashlight-container {
      padding: $tenpx 1.838rem 0 1.838rem;
      &.disabled{
        opacity: 0.5;
        pointer-events: none;
        cursor: default;
      }
    }

    .illumination-divider-margin {
      .illumination-divider {
        border-bottom: 0.06rem solid $borderColor4  !important;
        margin-right: $twelvepx  !important;
        margin-left: $twelvepx  !important;
      }
    }

    .item-container {
      padding: 0.3rem 1.063rem 0 1.063rem;

      .item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
      }

      .disabled {
        opacity: 0.5;
        pointer-events: none;
        cursor: default;
        background-color: transparent !important;
      }
    }

    .svg-icon {
      height: $twentyFourpx;
      width: $twentyFourpx;
    }

    .item-label {
      @extend .SegoeUIWhite14px;
      padding: 0.3rem;
      height: $fourtytwopx;
    }
  }
}

@media (max-width: $tablet) and (orientation: portrait) {
  .illumination-popover {
    .popover {
      left: -$tenpx  !important;
      top: -1rem  !important;
    }
  }
}

@media (min-width: $tablet) and (orientation: portrait) {
  .illumination-popover {
    .disabled{
      opacity: 0.5;
      pointer-events: none;
      cursor: default;
    }
    .popover {
      left: -1.538rem !important;
    }
  }
}