@import "~STYLES/colors.scss";
@import "~STYLES/pixelConvertConstants.scss";
@import "~STYLES/globalStyles.scss";

.SegoeUI {
  font-family: "SegoeUI";
}
.SegoeUIBold { 
  font-family: "SegoeUI Bold";
}
.SegoeUI600 {
  font-family: "SegoeUI SemiBold";
}

// Use the above basic fonts to declare the new ones

.SegoeUI22px {
  @extend .SegoeUI;
  font-size: $twentyTwopx;
}

.SegoeUIWhite13px {
  @extend .SegoeUI;
  font-size: $thirteenpx;
  color: $textColor1;
}

.SegoeUIWhite15px {
  @extend .SegoeUI600;
  font-size: $fifteenpx;
  color: $textColor1;
}

.SegoeUI20pxBold {
  @extend .SegoeUIBold;
  font-size: $twentypx;
  color: $textColor1;
}

.SegoeUI14px {
  @extend .SegoeUI;
  font-size: $fourteenpx;
}

.SegoeUI14Semibold {
  @extend .SegoeUI600;
  font-size: $fourteenpx !important;
}

.SegoeUIWhite14px {
  @extend .SegoeUI;
  font-size: $fourteenpx;
  color: $textColor1;
  font-weight: normal;
  letter-spacing: 0px;
  // width: 9em;
}

.SegoeUIWhite18px {
  @extend .SegoeUI;
  color: $textColor1;
  font-weight: normal;
  font-size: $eighteenpx;
  letter-spacing: 0px;
}

.SegoeUIWhite16px {
  @extend .SegoeUI;
  color: $textColor1;
  font-weight: normal;
  font-size: $sixteenpx;
  letter-spacing: 0px;
}

.SegoeUIWhite16pxBold {
  @extend .SegoeUIBold;
  color: $textColor1;
  font-size: $sixteenpx;
  letter-spacing: 0px;
}

.SegoeUIWhiteSemi16px {
  @extend .SegoeUI600;
  color: $textColor1;
  font-weight: normal;
  font-size: $sixteenpx;
  letter-spacing: 0px;
}

.SegoeUIRed14px {
  @extend .SegoeUI;
  font-size: $fourteenpx;
  color: $errorColor;
}

.SegoeUI12pxSemi {
  @extend .SegoeUI600;
  font-size: $twelvepx;
}

.SegoeUI18pxBold{
  @extend .SegoeUIBold;
  font-size: $eighteenpx;
  color: $textColor1;
}

.SegoeUI14pxBoldWhite{
  @extend .SegoeUIBold;
  font-size: $fourteenpx;
  color: $textColor1 !important;
}

.SegoeUI18pxSemiBold{
  @extend .SegoeUI600;
  font-size: $eighteenpx;
  color: $textColor1;
}

.SegoeUI17pxBoldWhite {
  @extend .SegoeUIBold;
  font-size: $seventeenpx;
  color: $textColor1;
}

.SegoeUI10pxBold {
  @extend .SegoeUIBold;
  font-size: $tenpx;
}

.SegoeUI12px {
  @extend .SegoeUI;
  font-size: $twelvepx;
}

.SegoeUIWhite26px {
  @extend .SegoeUI;
  color: $textColor1;
  font-weight: normal;
  font-size: $twentySixpx;
  letter-spacing: 0px;
}

.SegoeUI20pxSemiBold{
  @extend .SegoeUI600;
  font-size: $twentypx;
  color: $textColor1;
}

.SegoeUI20px{
  @extend .SegoeUI;
  font-size: $twentypx;
  color: $textColor1;
}

.SegoeUIBold34px{
  @extend .SegoeUIBold;
  font-size: $thirtyfourpx;
  color: $textColor1;
}

.SegoeUI61px{
  @extend .SegoeUI;
  font-size: $sixtyonepx;
}

.SegoeUI22pxSemiWhite{
  @extend .SegoeUI600;
  color: $textColor1;
  font-size: $twentyTwopx;
}

.SegoeUI23px {
  @extend .SegoeUI;
  font-size: $twentyThreepx;
}