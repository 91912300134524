// responsive breakpoints
$phone: 480px;
$tablet: 786px;
$laptop: 1024px;
$desktop: 1200px;

// background colors
$backgroundColor1: #313235;
$backgroundColor2: #00121c;
$backgroundColor3: #00263a;
$backgroundColor4: #00334e;
$backgroundColor5: #00a3e084;
$backgroundColor6: #001c2b;
$backgroundColor7: #01426a;
$backgroundColor8: #666666;
$backgroundColor9: #CADDE8;
$backgroundColor10: #00A3E0;
$backgroundColor11: #191818;
$backgroundColor12: #fff3f3;
$backgroundColor13: #000000;
$backgroundColor14: #ffffff;

$mobileFooterArrowBackground: #00416d;
$loginButtonColor1: #01578c;
$loginButtonColor2: #00121c;
$white: inherit;
$white61: #ffffff61;
$white50: #ffffff50;
$checkboxCheckedColor: #01578C;

//hover colors
$hoverPrimary: #00121c;
$hoverColor1: #057ec9;

//border colors
$borderColor1: #00121c;
$borderColor2: #d8dfe361;
$borderColor3: #ffffff;
$borderColor4: #a3b4bf;
$borderColor5: #f79280;
$borderColor6: #56bd8fbd;
$borderColor7: #ff7600a3;
$borderColor8: #A3B4BF62;
$borderColor9: #F5F8FA;
$borderColor10: #1C4A62;
$guestLoginBtnColor: #189A72;

$iconBorder: #f1f3f433;
$searchBorder: #ffffff50;
$parDivBorder: #3c4c57;

//text colors
$textColor1: inherit;
$textColor2: #ffffff61;
$textColor3: #00263a;
$textColor4: #ffffff;

// error text color
$errorColor: #ff4e4e;

$shadowColor1: #00000098;
$shadowColor5: #dc354540;
$textBoxShadowColor: rgba(13, 110, 253, 0.25%);
$muteBtnShadow: #00000026;
$customModalBoxShadow: #000000d6;

$dropdown-hover: #01426a;
$shortnamegb: #ee8733;
$uncheckswitchbg: #416b83;
$checkedBtnSwitch: #00a3e0;
$uncheckedBtnSwitch: #00000080;
$sliderSwitchBackground: #014D81;
$linkColor: #76D7FF;

$captionsBgColor: #000000A0;
$captionsBorder: #707070;
$nlpWordColor: #D9CC45;