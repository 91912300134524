@import "~STYLES/colors.scss";
@import "~STYLES/fonts.scss";

/// This class is used to style CustomSearchableDropdown Component
/// @group custom-components
.custom-searchable-dropdown {

  .custom-searchable-dropdown__control {
    @extend .SegoeUI14px;
    cursor: pointer;
    min-height: auto;
    box-shadow: none;
    color: $textColor1;
    height: $thirtysixpx;
    background: $backgroundColor2;
    border: $onepx solid $borderColor3;

    &:hover {
      border: $onepx solid $borderColor3;
    }

    &.custom-searchable-dropdown__control--is-focused {
      border: $onepx solid $borderColor3;
    }

    .custom-searchable-dropdown__value-container {
      margin: 0.375rem 0 0.375rem 0.75rem;
      padding: 0;

      .custom-searchable-dropdown__single-value {
        @extend .SegoeUI14px;
        margin: 0;
        color: $textColor1;
      }

      .custom-searchable-dropdown__input-container {
        margin: 0;
        padding: 0;
        color: inherit;

        .custom-searchable-dropdown__input {
          border: none !important;
        }
      }

      .custom-searchable-dropdown__placeholder {
        @extend .SegoeUI14px;
        margin: 0;
        opacity: 0.75;
        color: inherit;
      }
    }

    .custom-searchable-dropdown__indicators {
      padding-right: 0.75rem;
    }
  }

  .custom-searchable-dropdown--is-disabled {
    opacity: 0.4;
    user-select: none;
    pointer-events: none;
  }

  .custom-searchable-dropdown__menu {
    margin: 0;
    background-color: $backgroundColor2;
    border: $onepx solid $borderColor3;

    .custom-searchable-dropdown__menu-list {
      padding: 0.5rem 0;

      .custom-searchable-dropdown__option {
        @extend .SegoeUI14px;
        border: none;
        cursor: pointer;
        color: $textColor1;
        padding: 0.25rem 1rem;
        word-break: break-word;
        background-color: transparent;

        &:hover {
          background-color: $dropdown-hover;
        }
      }

      .custom-searchable-dropdown__menu-notice--no-options {
        @extend .SegoeUI14px;
        color: $textColor1;
        padding: 0;

        &:hover {
          background-color: $dropdown-hover;
        }
      }
    }
  }
}