@import '~STYLES/colors.scss';
@import '~STYLES/fonts.scss';

@import '~STYLES/pixelConvertConstants.scss';

/// This class is used to style Dashboard Component
/// @group dashboard
.dashboard-layout {
    height: 100%;

    .dashboard-sub-component {
        height: calc(100% - 4.526rem);
        overflow-y: hidden;
        top: 4.526rem;
        position: relative;

        .contact-pane {
            height: 100%;
        }

        .contact-pane-mobile {
            overflow-y: auto;
            height: 100%;
        }

        .dashboard-container {
            overflow: hidden !important;
            background-color: $backgroundColor11;
            height: 100%;

            .sidebar {
                height: 100%;
                z-index: 3;
            }

            // .sidebarScrollOnLandscape {
            //     // overflow-y: auto !important;
            //     // overflow-x: hidden;
            // }

            .contacts-header {
                @extend .SegoeUI20pxBold;
                text-align: center;
                letter-spacing: 0em;
                opacity: 1;
            }

            .highlight-contact {
                background: $dropdown-hover;
                margin-top: 0.2rem;
                margin-bottom: 0.2rem;
            }

            .contact-name {
                width: max-content;
                z-index: 99 !important;
            }

            .disable-icon {
                opacity: 0.5;
            }

            .common-div {
                width: fit-content;
                cursor: pointer;
                align-items: center;
                display: flex;
            }

            .add-text {
                @extend .SegoeUI14px;
                text-align: left;
                letter-spacing: 0em;
                color: $white;
                opacity: 1;
                padding-top: 0.3em;
                cursor: pointer;
            }

            .text-field-component input:-webkit-autofill {
                box-shadow: 0 0 0rem $thousandpx $backgroundColor3 inset;
                -webkit-text-fill-color: $textColor4;
            }

            @media (orientation: portrait) {
                .callhistory-arrow-mob {
                    //mobile portrait
                    margin-right: 0.45rem;
                }
            }

            @media (orientation: landscape) and (min-width: 1120px) {
                .callhistory-arrow-tab {
                    //tab landscape
                    margin-right: 0.5rem;
                }

                .call-history-sidebar-header {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .search-sidebar-header {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .contact-list {
                    max-height: calc(100% - 5rem);
                }

                .call-history {
                    max-height: calc(100% - 5rem) !important;
                }
            }


            .divider-margin {
                margin-right: 0.1rem !important;
                margin-left: 0.1rem !important;
            }

            .search {
                border: $onepx solid $white50 !important;
                border-radius: $fourpx;
                opacity: 1;
                color: $textColor1 !important;
                background-color: transparent !important; // $backgroundColor2
                font-size: 0.9375em !important;
            }

            #basic-addon1 {
                opacity: 1;
                border-radius: $fourpx;
                //background-color:  $backgroundColor2 !important;
                border: $onepx solid $white50 !important;
                border-right: 0rem !important;
                padding: $twopx;
            }

            .add-contact-icon {
                width: 1.5rem;
                height: 1.5rem;
                opacity: 1;
                cursor: pointer;
                margin-right: 0.3rem;
                margin-left: 1rem;
            }

            .contact-list {
                max-height: calc(100% - 11.125rem);
                margin-top: $tenpx;
            }

            .add-contact {
                display: flex;
                height: 5rem;
                align-items: center;
                bottom: 0;
            }

            .sipaddr {
                @extend .SegoeUI14px;
                opacity: 0.6;
            }

            @media (min-width: 481px) and (max-width: 915px) {

                .page-header,
                .contact-list,
                .guest-invite-margin {
                    margin-right: 7rem !important;
                    margin-left: 7rem !important;
                    justify-content: space-between;
                }

                @media (orientation: portrait) {

                    //portrait tab/ipad call history(#620)
                    .landscape-arrow {
                        margin-right: 1rem;
                    }

                    .sidebar-search {
                        margin-left: 7rem;
                        margin-right: 7rem;
                    }

                    .add-contact {
                        margin-left: 7rem;
                        margin-right: 7rem;
                    }
                }

                @media (orientation: landscape) {
                    .add-contact {
                        height: 3rem !important;
                    }

                    .call-history-sidebar-header {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .search-sidebar-header {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                @media (orientation: landscape) and (max-height: 500px) {

                    // landscape big mobiles(#620)
                    .landscape-arrow {
                        margin-right: 1rem;
                    }
                }
            }

            @media (min-width: 1201px) {
                .history-header {
                    margin-left: 0rem;
                }
            }

            .call-history-header {
                @extend .SegoeUI20pxBold;
            }

            .search-header {
                @extend .SegoeUI20pxBold;
            }

            @media (min-width: 1025px) {
                .call-history-header {
                    text-align: left;
                    padding-right: 0.5rem;
                }
            }

            // Alignment for #1006
            @media (min-width: 1400px) {
                .call-history-header {
                    text-align: left;
                    padding-left: 0.5rem;
                }
            }

            @media (min-width: 1800px) {
                .call-history-header {
                    text-align: left;
                    padding-left: 0.8rem;
                }
            }

            @media (min-width: 1025px) and (max-width: 1200px) {
                .padding-right-ipad-landscape {
                    padding-right: 1rem !important;
                }

                .delete-button {
                    margin-right: 0rem;
                }

                .delete-button-fr {
                    margin-right: 1.5rem;
                }
            }

            // nocontacts, histosry,add contact label - mobile
            @media (max-width: 768px) {
                .landscape-horizontal-padding-option {
                    padding-left: 0rem !important;
                }
            }

            // nocontacts, histosry,add contact label - desktop
            @media (min-width: $laptop) {
                .landscape-horizontal-padding-option {
                    padding-left: 0rem !important;
                }
            }

            .landscape-horizontal-padding-option:focus-visible {
                outline: $onepx dashed $borderColor3 !important;
            }

            @media only screen and (min-device-width: 481px) and (max-device-width: 769px) and (orientation: landscape) {
                .landscape-horizontal-padding {
                    padding-left: 1.5rem;
                }

                .call-history-sidebar-header {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .search-sidebar-header {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            @media only screen and (min-device-width: 769px) and (max-device-width: 769px) and (orientation: portrait) {
                .landscape-horizontal-padding {
                    padding-left: 1.5rem;
                }

                .call-history-sidebar-header {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .search-sidebar-header {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .delete-button {
                box-shadow: 0em 0.0625em 0.125em $muteBtnShadow;
                border: 0.0625em solid $borderColor4;
                border-radius: 0.1875em;
                opacity: 1;
                background-color: transparent;
                justify-content: flex-end;
                min-width: max-content;
                cursor: default !important;
            }

            .delete-button-fr {
                box-shadow: 0em 0.0625em 0.125em $muteBtnShadow;
                border: 0.0625em solid $borderColor4;
                border-radius: 0.1875em;
                opacity: 1;
                background-color: transparent;
                justify-content: flex-end;
                min-width: max-content;
                cursor: default !important;
            }

            .delete-text {
                @extend .SegoeUI12pxSemi;
                display: flex;
                justify-content: center;
                align-items: center;
                letter-spacing: 0em;
                color: $white;
                text-transform: capitalize;
                opacity: 1;
            }

            .callee-name {
                @extend .SegoeUIWhite15px;
                text-align: left;
                letter-spacing: 0em;
                padding: 0.5em;
            }

            .contact-name-list {
                @extend .SegoeUIWhiteSemi16px;
                text-align: left;
                letter-spacing: 0em;
                padding-bottom: 1.25em;
                padding-top: 1em;
                padding-left: 1rem;
            }

            @media (min-width: 320px) and (max-width: 1024px) {
                .contact-name-list {
                    padding-bottom: 1em; //0.8em;
                    padding-top: 0.725em;
                }

            }

            .highlight-contact {
                background: $dropdown-hover;
            }

            .contact-name {
                width: max-content;
                z-index: 99 !important;
            }

            .common-div {
                width: fit-content;
                cursor: pointer;
                text-align-last: center;
            }

            .add-text {
                @extend .SegoeUI14px;
                text-align: left;
                letter-spacing: 0em;
                color: $white;
                opacity: 1;
                padding-top: 0.3em;
                cursor: pointer;
            }

            .text-field-component input:-webkit-autofill {
                box-shadow: 0 0 0rem $thousandpx $backgroundColor3 inset;
                -webkit-text-fill-color: $textColor4;
            }

            .divider {
                height: 0px;
                opacity: 0.38;
                margin: 0.650rem 0.725rem 0.650rem 0.725rem;
            }

            .contact-divider {
                // width: 303px;
                height: 0rem;
                border-bottom: 0.03rem solid $parDivBorder;
                opacity: 1;
            }

            .divider-margin {
                margin-right: 0.1rem !important;
                margin-left: 0.1rem !important;
            }

            .search {
                border: $onepx solid $white50 !important;
                border-radius: $fourpx;
                opacity: 1;
                color: $textColor1 !important;
                background-color: transparent !important; // $backgroundColor2
                font-size: 0.9375em !important;
            }

            #basic-addon1 {
                opacity: 1;
                border-radius: $fourpx;
                border: $onepx solid $white50 !important;
                border-right: 0rem !important;
                padding: $twopx;
            }

            .add-contact-icon {
                width: 1.5rem;
                height: 1.5rem;
                opacity: 1;
                cursor: pointer;
                margin-right: 0.3rem;
                margin-left: 1rem;
            }

            .sipaddr {
                @extend .SegoeUI14px;
                opacity: 0.6;
            }

            @media (min-width: 481px) and (max-width: 820px) {

                // 768
                .page-header,
                .contact-list,
                .guest-invite-margin {
                    margin-right: 7rem !important;
                    margin-left: 7rem !important;
                    justify-content: space-between;
                }
                .guest-invite-scroll {
                    overflow-y: auto;
                    overflow-x: hidden;
                    max-height: 66%;
                }
                .add-contact {
                    margin-right: 7rem !important;
                    margin-left: 7rem !important;

                }

                .call-history-sidebar-header {
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    margin-left: 7rem;
                    margin-right: 7rem;
                }

                .search-sidebar-header {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .sidebar-header {
                    height: 1.875rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0 6rem 0 6rem;
                }

                @media (orientation: landscape) {

                    //landscape mobile call history(#620)
                    .landscape-arrow {
                        margin-right: 1rem;
                    }
                }
            }

            @media (min-width: 768px) and (max-width: 1023px) {
                .sidebar {
                    overflow: hidden;
                }

                .sidebar-search {
                    display: flex;
                    align-items: center;
                    padding: 0;
                    justify-content: center;
                    margin-left: 10rem;
                    margin-right: 10rem;
                }

                .sidebar-header {
                    height: 1.875rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0 10rem 0 10rem;
                }

                .page-header,
                .contact-list,
                .guest-invite-margin {
                    margin-right: 10rem !important;
                    margin-left: 10rem !important;
                    justify-content: space-between;
                    align-items: center;
                }
                .guest-invite-scroll {
                    overflow-y: auto;
                    overflow-x: hidden;
                    max-height: 81%;
                }
                .add-contact {
                    align-items: center;
                    justify-content: center;
                    margin-right: 10rem !important;
                    margin-left: 10rem !important;

                    .common-div {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align-last: center;

                        .add-contact-icon {
                            margin: 0;
                            padding: 0;
                        }

                        .add-text {
                            margin-left: 10px;
                            align-items: center;
                            justify-content: center;
                            display: flex;
                            padding: 0 !important;
                        }
                    }
                }

                .call-history {
                    max-height: calc(100% - 5rem) !important;
                }

            }

            @media (min-height: 1300px) and (orientation: portrait) {

                .page-header,
                .contact-list {
                    justify-content: space-between;
                    align-items: center;
                }

                .add-contact {
                    align-items: center;
                    justify-content: center;
                    margin-right: 7rem !important;
                    margin-left: 7rem !important;
                }

                .sidebar-header {
                    height: 1.875rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0 7rem 0 7rem;
                }
            }

            @media (min-width: 320px) and (max-width: 1024px) {
                .alignment {
                    text-align: center !important;
                    padding-left: 0rem;
                    padding-right: 1.8rem;
                }
            }

            .margin {
                margin-left: -0.5rem;
            }

            .margin-mobile {
                margin-right: 0.5rem;
                margin-left: 0.5rem;
            }

            .search-bar {

                .text-field-component .form-control,
                .text-field-component :focus {
                    height: 2.5rem; //1.875rem;

                    &[data-left-icon='true'] {
                        padding-left: 2.9em;
                    }
                }

                .left-icon {
                    top: 22%; //10%;
                    left: 3%;
                    opacity: 0.4;
                }

                @media (min-width: 320px) and (max-width: 1024px) {

                    .text-field-component .form-control,
                    .text-field-component :focus {
                        height: 2.5rem;

                        &[data-left-icon='true'] {
                            padding-left: 2.9em;
                        }
                    }

                    .left-icon {
                        top: 22%;
                        left: 3%;
                        opacity: 0.4;
                    }
                }
            }

            //-----add contact------
            .cancel-btn,
            .cancel-btn:hover {
                @extend .SegoeUI14Semibold;
                width: 6.25rem;
            }

            .form-label {
                @extend .SegoeUI;
                color: $textColor1;
                font-size: 1rem;
            }

            .field {
                @extend .SegoeUI14px;
            }

            .add-btn,
            .add-btn:hover {
                @extend .SegoeUI14Semibold;
                width: 6.25rem;
            }


            @media (max-width: $tablet) {

                .contact-list {
                    max-height: calc(100% - 12.125rem);
                }

                .call-history {
                    max-height: calc(100% - 5rem) !important;
                }

                .call-history-sidebar-header {
                    justify-content: space-around;
                }

                .search-sidebar-header {
                    justify-content: space-around;
                }

            }

            @media (min-width: $tablet) and (orientation: landscape) {
                .add-contact {
                    height: 3rem;
                }

                .call-history-sidebar-header {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .search-sidebar-header {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            // Contacts and call history title in ipad landscape
            // Commented to resolve ipad - sidebar header cut off issue 
            // @media (min-width: 1023px) and (max-width: 1024px) and (orientation: landscape) {
            //     .contact-title {
            //         margin-top: 4.5rem;
            //     }
            // }

            .date-time {
                @extend .SegoeUIWhite13px;
                padding: 0%;
                min-width: max-content;
            }

            .pl-3 {
                padding-left: 1rem;
            }
        }
    }

    //ipad portrait 
    @media (min-width:760px) and (min-height: $laptop) and (orientation:portrait) {
        .dashboard-sub-component {
            height: 100% !important;
            top: 0 !important;
        }
    }

    @media (max-width: 1024px) {
        .call-history {
            max-height: calc(100% - 9rem) !important;
        }
    }


    @media (min-width: $desktop) {}

    @media (max-width: 1226px) and (min-width: 1024) {}

    @media (max-width: $tablet) {

        .page-header,
        .contact-list,
        .guest-invite-margin {
            justify-content: space-between;
            align-items: center;
        }
    }

    @media (min-width: 760px) and (min-height: 700px) {

        // ipad(#603) 
        // .contact-list {
        //     max-height: calc(100% - 18rem) !important;
        // }
    }

    // Commented for responsiveness changes 
    // @media (min-width: $laptop)and(max-height: $desktop)and (orientation:landscape) {
    //     .sidebar-search{
    //         margin-left: 1rem;
    //         margin-right: 1rem;
    //     }
    //     .add-contact{
    //         margin-right: 10rem !important;
    //         margin-left: 10rem !important;
    //     }

    // }
    @media (min-height: $phone) and (max-height:$laptop) and (orientation:landscape) {
        .call-history-sidebar-header {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .search-sidebar-header {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    // #1006 - fot tab/ipad in portrait mode
    @media (orientation: portrait) {
        .contact-tablet {
            margin-left: 0.5rem;
        }
    }

    //scroll for add contact panel
    // @media (max-width: $tablet) {
    //   .addcontact-scroll {
    //     max-height: calc(100% - 4.526rem);
    //     overflow-y: auto !important;
    //     overflow-x: hidden !important;
    //   }
    // }

    @media (max-width: $tablet) {
        .scroll-div-add {
            height: calc(100% - 1.15rem);
            overflow-y: auto !important;
            overflow-x: hidden !important;
        }
    }

    @media only screen and (max-width: $tablet) and (orientation:landscape) {
        @supports (-webkit-touch-callout: none) {
            /* CSS specific to iOS devices */

            .sidebar-header {
                height: 1.875rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 10rem 0 10rem;
            }

            .sidebar-search {
                display: flex;
                align-items: center;
                padding: 0;
                justify-content: center;
                margin-left: 10rem;
                margin-right: 10rem;

            }

            .call-history-sidebar-header {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .search-sidebar-header {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    /* ipad Mini Landscape */
    @media only screen and (width:$laptop) and (orientation: landscape) {
        .contact-list {
            max-height: calc(100% - 15.125rem) !important;
        }
    }

    //  Samsung S9 
    @media only screen and (width: 360px) and (height: 740px) and (orientation: portrait) {
        .sidebar-header {
            margin: 0 !important;
        }

        .sidebar-search {
            margin: 0 !important;
        }
    }
}

// Welcome css
.welcome-container {
    display: flex;
    align-items: center !important;
    height: 100% !important;
}

.welcome-text1 {
    @extend .SegoeUIBold34px;
    text-align: -webkit-center;
}

.welcome-text2 {
    @extend .SegoeUI22px;
    color: $textColor1;
    text-align: -webkit-center;
    display: block !important;
}

.welcome-text1-mob {
    @extend .SegoeUI20pxBold;
    text-align: -webkit-center;
}

.welcome-text2-mob {
    @extend .SegoeUIWhite14px;
    text-align: -webkit-center;
    display: block !important;
}

.welcome-buttons {
    @extend .SegoeUI18pxSemiBold;
    width: 8rem;
}

.no-contacts {
    @extend .SegoeUIWhite16px;
    color: $textColor1 !important;
    opacity: 1;
    text-align: left;
    line-height: $twentyOnepx;
}

// Guest invite styles
.guest-invite-scroll {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 89%;
}

.guest-field {
    @extend .SegoeUIWhite16px;
    font-size: $sixteenpx !important;
    line-height: 1.33rem;

    .char-count {
        opacity: 0.5 !important;
    }
}

.guest-btn {
    @extend .SegoeUI14Semibold;
    line-height: 1.16375rem !important;
    font-weight: 700 !important;
}

// Guest invitation success popup
#guest-invite-success {
    .message-text {
        @extend .SegoeUIWhite16px;
        font-size: $sixteenpx !important;
        line-height: $twentyOnepx !important;
    }

    .header1 {
        @extend .SegoeUIWhiteSemi16px;
        font-size: $sixteenpx !important;
        line-height: $twentyOnepx !important;
    }
}

// Existing guest invite users panel 
.existing-name {
    @extend .SegoeUIWhite16px;
    font-size: $sixteenpx !important;
    font-weight: 700;
    line-height: 1.33rem;
}

.existing-email {
    @extend .SegoeUIWhite14px;
    font-size: $fourteenpx !important;
    font-weight: 400;
    line-height: 1.1875rem;
}