@import '~STYLES/colors.scss';
@import '~STYLES/fonts.scss';

#canvas_for_telestration {
    overflow: hidden;
    position: absolute;
}

#canvas_for_img_telestration {
    overflow: hidden;
    position: absolute;
    left: 0;
    z-index: 1;
}

/// This class is used to style CallDashboard Component
/// @group call-dashboard
.view-port-h{
    height: 100vh !important;
}
.call-dashboard-layout {
    height: 100%;
    position: relative;

    .backdrop-class {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0.7;
        background-color: $backgroundColor13;
        z-index: 102;
    }

    .call-dashboard-sub-component {
        overflow: hidden;
        position: relative;
        margin: 0;
        align-items: center;
        justify-content: center;

        .call-dashboard-container {
            background-color: $backgroundColor11;
            height: 100%;
            overflow: hidden;

            .sidebar {
                height: 100%;
                z-index: 3;
                overflow-y: auto;
                overflow-x: hidden; // Removed horizontal scroll from settings sidebars on devices
            }

            @media (max-width: 992px) {

                // 1024px
                .sidebar {
                    position: absolute;
                    height: 100%;
                }
            }

            .video-call-panel {
                height: 100%;
            }

            .video-image-container {
                position: relative;
                height: 100%;

                .exit-image {
                    position: absolute;
                    right: 1.5rem;
                    top: 1.5rem;
                    opacity: 1;
                    z-index: 101;
                }

                .img {
                    object-fit: contain;
                }

            }

            .hide-exit-image {
                z-index: 0 !important;
            }

            .video-image-container:after {
                display: block;
                content: '';
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 100;
                position: relative;
                width: inherit;
            }

            .image-send-mode:after {
                border: 15px solid $borderColor6;
            }

            .image-receive-mode:after {
                border: 15px solid $borderColor7;
            }

            .vertical-video {
                position: relative;
            }

            .horizontal-video {
                position: relative;
                width: fit-content;
                height: 100%;
            }

            .tab-width {
                position: relative;
                height: 100%;
            }

            .video-image-panel {
                .exit-image {
                    position: absolute;
                    right: 1.2em;
                    top: 1.2rem;
                    opacity: 1;
                    z-index: 101;
                }
            }

            .video-image-panel:after {
                display: block;
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }

            .video-align-center-on-ipadPro {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .video-fallback-UI {
                height: 100%;

                .avatar {
                    border-radius: 50%;
                    height: 6.25rem;
                    width: 6.25rem;
                    margin: 0 auto !important;
                }

                .avatar-text {
                    // @extend .SegoeUI61px; Need to modify when mockups are updated
                    font-size: 3.25rem;
                    color: $borderColor1;

                    .letter-position {
                        margin: 0px !important;
                    }
                }

                .avatar-name-text {
                    @extend .SegoeUI22pxSemiWhite;
                }

                .avatar-col {
                    @extend .align-center;
                    flex-direction: column;
                    max-width: min-content;
                }
            }

            .tooltip-container {
                // background-color: red;
                height: 5rem;
                width: 5%;
                top: 40%;
                left: 5%;
                float: left;
                position: absolute !important;
                overflow-y: hidden;
            }

            // NLP Captions UI - desktop
            .captions-container {
                background-color: $captionsBgColor;
                border: 1px solid $captionsBorder;
                border-radius: $eightpx;
                height: 5.0625rem;
                width: 42%;
                z-index: 1;
                top: 2%;
                left: 2%;
                float: left;
                position: absolute !important;
                overflow-y: hidden;
                white-space: pre-line;

                .nlp-word {
                    @extend .SegoeUI23px;
                    color: $nlpWordColor;
                    line-height: $twentySevenpx;
                    position: relative;
                    margin: 0px;
                }

                .nlp-line {
                    @extend .SegoeUI23px;
                    color: $white;
                    line-height: $twentySevenpx;
                    position: relative;
                    margin: 0px;
                }

                .b-0 {
                    bottom: 0px !important;
                }
            }

            // NLP Captions UI - devices
            .captions-container-device {
                background-color: $captionsBgColor;
                border: 1px solid $captionsBorder;
                border-radius: $eightpx;
                height: 5.0625rem;
                margin: 0 0.25rem 0 0.25rem;
                align-self: center;
                z-index: 2;
                top: 12%;
                left: 1%;
                float: left;
                position: absolute !important;
                overflow-y: hidden;
                white-space: pre-line;
                width: 96%;

                .nlp-word {
                    @extend .SegoeUIWhite18px;
                    color: $nlpWordColor;
                    line-height: $twentySevenpx;
                    position: relative;
                    margin: 0px;
                }

                .nlp-line {
                    @extend .SegoeUIWhite18px;
                    color: $white;
                    line-height: $twentySevenpx;
                    position: relative;
                    margin: 0px;
                }
                .b-0 {
                    bottom: 0px !important;
                }
            }
        }
    }

    .call-dashboard-subcomponent-landscape {
        height: calc(100% - 4.526rem) !important;
        bottom: 0;
        position: absolute;
    }

    .call-dashboard-sub-component-height {
        height: calc(100% - 9.126rem) !important;
    }

    .video-style-on-browser {
        width: 100%;
    }

    .video-style-on-landscape {
        // Made height 100% to cover video coverage in landscape mode for devices #2397
        height: 100%; 
        width: 100%;
    }

    .screen-share-border {
        height: inherit;
        width: auto;
        border: 15px solid $borderColor6;
    }

    .screen-share-border-remote {
        width: auto;
        height: 100%;
        border: 15px solid $borderColor7;
    }

    .screen-share-border-remote-mobile {
        width: 94%;
        height: auto;
        border: 15px solid $borderColor7;
    }

    .call-header {
        height: 4.563rem !important;
        top: 0%;
        position: fixed;
        z-index: 101;

        // Media queries
        .icons-set {
            padding-right: 1rem;
        }

        @media (max-width: 480px) {
            .left-border {
                width: 5% !important;
            }
        }

        @media (min-width: $phone) and (max-width: $tablet) {
            .left-border {
                width: 2% !important;
            }
        }

        @media (min-width: 769px) and (max-width: 1024px) {
            .left-border {
                width: 2% !important;
            }
        }
    }

    .footer {
        padding: 0;
        margin: 0;
        bottom: 0;
        height: 4.563rem !important;
        position: fixed;
        z-index: 101;
    }

    @media (min-width: 300px) and (orientation: portrait) {
        .call-dashboard-sub-component {
            top: 0 !important;
            height: 100% !important;

            .video-call-panel {
                height: 100% !important;

            }
        }
    }

    @media (max-width: 500px) and (orientation: landscape) {
        .call-dashboard-sub-component {
            height: 100% !important;
        }
    }

    @media (min-width: $phone) and (max-width: $laptop) and (orientation: landscape) {
        .call-dashboard-sub-component {
            height: 100%;

            .video-call-panel {
                height: 100% !important;
                align-items: center;
            }

            .call-dashboard-container .video-fallback-UI {
                height: 100% !important;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .video-call-panel-landscape {
                height: 100% !important;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

@media (min-width: 1920px) {
    .screen-share-border {
        width: auto;
    }
}

@media (min-width: $tablet) {
    .call-dashboard-sub-component-tab {
        top: 4.526rem;
    }
}


@media (min-width: $tablet) and (max-width: $laptop) and (orientation: landscape) {
    .screen-share-border-remote {
        width: 98% !important;
    }
}

.consent-modal {
    width: 530px !important;

    .modal-body {
        padding: 1rem 0.15rem 1rem 0.75rem;

        .modal-body-contents {
            @extend .SegoeUIWhite16px;
            font-size: $sixteenpx  !important;
        }
    }
}