@import '~STYLES/colors.scss';
@import '~STYLES/fonts.scss';
@import '~STYLES/pixelConvertConstants.scss';

/// This class is used to style CustomForm Component
/// @group custom-components
.custom-form {
    color: $textColor1;
    position: relative;
    .was-validated {
        .form-control:invalid,
        .form-control:invalid:focus {
            border: $onepx solid $errorColor;
            //  padding: 0.375rem 2.5em;;
        }
        .form-control:valid,
        .form-control:valid:focus {
            // @extend .LatoBold;
            border: $onepx solid $borderColor1;
            // padding: 0.375rem 2.5em;
        }
        .form-control[aria-invalid='true'] {
            border: $onepx solid $errorColor;
            //  padding: 0.375rem 2.5em;
        }
        .form-control:focus[aria-invalid='true'] {
            box-shadow: 0 0 0 0.2rem $shadowColor5;
        }
        .custom-dropdown {
            button[aria-invalid='true'] {
                border: $onepx solid $errorColor;
                padding: 0.375rem 0.75rem;
            }
            button:focus[aria-invalid='true'] {
                box-shadow: 0 0 0 0.2rem $shadowColor5;
            }
        }

        // Temporary done as radio button changes to green because of the library behaviour for validated
        .form-check-input:valid:checked {
            background-color: #0d6efd !important;
            border-color: #0d6efd !important;
        }
        .form-check-input:valid~.form-check-label {
            color: $white !important;
        }
    }
}
@media (min-width: 1920px) {
    .custom-form {
        max-width: 29.525rem;
    }
}

@media (min-width: 2520px) {
    .custom-form {
        max-width: 40rem;
    }
}