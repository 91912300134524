@import "~STYLES/colors.scss";
@import "~STYLES/fonts.scss";

// Toast icon for info, success, warning, error toasts
/// This class is used to style Toast Component
/// @group custom-components
.Toastify__toast-icon {
    align-self: flex-start !important;
    margin-inline-end: 20px !important;
    margin-top: 0.5em;
}

@media only screen and (min-width : 480px) {
    .Toastify__toast-container {
        width: max-content !important;
        max-width: 450px;
        min-width: 200px;
    }

    .Toastify__toast-container--bottom-center {
        bottom: 1em !important;
        right: 1em !important;
        left: auto !important;
        transform: none !important;
        margin-bottom: 60px;
    }
}

// Toast styling
.Toastify__toast-theme--colored.Toastify__toast--default,
.Toastify__toast-theme--colored.Toastify__toast--info,
.Toastify__toast-theme--colored.Toastify__toast--success,
.Toastify__toast-theme--colored.Toastify__toast--warning,
.Toastify__toast-theme--colored.Toastify__toast--error {
    @extend .SegoeUIWhite14px;
    color: $textColor1 !important;

    // Close button
    .toast-close {
        width: 1.0rem;
        height: 1.0rem;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        margin-top: 0.5rem;
    }
}